import React, { useEffect, useState, useRef } from "react";
import Product from "../components/products/Product";
import { useDispatch, useSelector } from "react-redux";
import { getAllProductsWithFilter } from "../redux/actions/productAction";
import {getAllCategory} from "../redux/actions/categoryAction";
import Spiner from "../utilities/Spiner";
import Pagination from "../utilities/Pagination";
import Filter from "../images/svg/filter.svg";
import Close from "../images/svg/close.svg";
import { Helmet } from "react-helmet-async";

const SummerCollection = () => {
  const dispatch = useDispatch();

  const products = useSelector((state) => state.productReducer.productsFilter);
  const categoriesData = useSelector(
    (state) => state.categoryReducer.categories
  );
  const [allProducts, setAllProducts] = useState([]);
  const [productLoading, setProductLoading] = useState(true)
  const [allCategories, setAllCategories] = useState([]);
  const [pageNumber, setPageNumber] = useState("");
  const [pageResult, setPageResult] = useState("");

  const [ageFilter, setAgeFilter] = useState("")
  const [showFilter, setShowFilter] = useState(false);


  const focus = useRef();
  let limit = 20;


  //get all products
  useEffect(() => {
    dispatch(getAllProductsWithFilter(`season=صيف&limit=${limit}`));
    setTimeout(()=>{
      focus.current.click();
    },2000)
  }, []);

  useEffect(() => {
    if (products) {
      if (products.data) {
        setAllProducts(products.data.data);
        setPageResult(products.data.paginationResult);
        setProductLoading(false)
      }
    }
  }, [products]);


  //get all categories
  useEffect(() => {
    dispatch(getAllCategory());
  }, []);

  useEffect(() => {
    if(categoriesData){
      if(categoriesData.status === 200){
        if(categoriesData.data){
            setAllCategories(categoriesData.data.data)
        }
    }
    }
  }, [categoriesData]);


  useEffect(()=>{
    if(ageFilter !== ""){
      dispatch(getAllProductsWithFilter(`season=صيف&age=${ageFilter}&limit=${limit}`));
    }else{
      dispatch(getAllProductsWithFilter(`season=صيف`));
    }
    setShowFilter(false)
  },[ageFilter])

  useEffect(() => {
    dispatch(getAllProductsWithFilter(`season=صيف&limit=${limit}&page=${pageNumber}`));
  }, [pageNumber]);

  //to get page number
  const getPage = (pageNumber) => {
    setProductLoading(true)
    setPageNumber(pageNumber);
  };


  return (
    <div className="category-page">
      <Helmet>
        <title>{`SHORTCUT | منتجات الصيف`}</title>
      </Helmet>
      <div className="container">
        {productLoading ? (
          <Spiner />
        ) : (
          <div className="category-page-wraper">
            <div className="products">
              <div className="cat-title">
                <h2>
                <img className="filter-img" src={Filter} alt="filter" onClick={()=> setShowFilter(true)} />
                <span>({allProducts.length})</span>
                منتجات الصيف
                </h2>
              </div>
              <div className="products-wraper">
                {allProducts.length > 0 ? (
                  allProducts.map((product, index) => (
                    <Product key={index} product={product} />
                  ))
                ) : (
                <p className="no-items">لا يوجد منتجات حتى الأن</p>
                )}
              </div>
            </div>
            <div className="filter">
              <div className="category-type">
                <h2>التصنيفات</h2>
                <ul>
                  {allCategories.length > 0
                    ? allCategories.map((cat, index) => (
                        <li key={index}>
                          <a href={`/category/${cat._id}`}>{cat.name}</a>
                        </li>
                      ))
                    : null}
                </ul>
              </div>
              <div className="category-type">
                <h2>الفئة</h2>
                <ul>
                <div className="select">
                    <label htmlFor="all">الكل</label>
                    <input
                      id="all"
                      name="select-age"
                      ref={focus}
                      type="radio"
                      value={""}
                      onClick={(e) => setAgeFilter(e.target.value)}
                    />
                  </div>
                  <div className="select">
                    <label htmlFor="men">رجال</label>
                    <input
                      id="men"
                      name="select-age"
                      type="radio"
                      value={"رجال"}
                      onClick={(e) => setAgeFilter(e.target.value)}
                    />
                  </div>
                  <div className="select">
                    <label htmlFor="kids">أطفال</label>
                    <input
                      id="kids"
                      name="select-age"
                      type="radio"
                      value={"أطفال"}
                      onClick={(e) => setAgeFilter(e.target.value)}
                    />
                  </div>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="pagnation-container">
        <Pagination pageResult={pageResult} onPress={getPage} />
      </div>
      <div className="mobile-filter" style={showFilter ? {marginLeft: "0"}: {marginLeft: "-50%"}}>
        <img className="close" src={Close} alt="close" onClick={()=>setShowFilter(false)} />
        <div className="filter-container">
          <ul>
            <div className="select">
              <label htmlFor="all">الكل</label>
              <input
                id="all"
                name="select-age"
                ref={focus}
                type="radio"
                value={""}
                onClick={(e) => setAgeFilter(e.target.value)}
              />
            </div>
            <div className="select">
              <label htmlFor="men">رجال</label>
              <input
                id="men"
                name="select-age"
                type="radio"
                value={"رجال"}
                onClick={(e) => setAgeFilter(e.target.value)}
              />
            </div>
            <div className="select">
              <label htmlFor="kids">أطفال</label>
              <input
                id="kids"
                name="select-age"
                type="radio"
                value={"أطفال"}
                onClick={(e) => setAgeFilter(e.target.value)}
              />
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SummerCollection