import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createUser } from "../../redux/actions/authAction";
import { getLoggedUser } from "../../redux/actions/authAction";
import { toast } from 'react-toastify';
import BtnLoader from "../../utilities/BtnLoader";
import { Helmet } from "react-helmet-async";

const Signup = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [phone, setPhone] = useState("");

  const [role, setRole] = useState("");
  const [btnLoaderStatus, setBtnLoaderStatus] = useState(false)


  const userData = useSelector((state) => state.authReducer.signup);
  const loggedUserData = useSelector((state) => state.authReducer.getMe);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const notifyError = (msg) => toast.error(msg);
  const notifysuccess = (msg) => toast.success(msg);

  const handelSubmit = (e) => {
    e.preventDefault();
    setBtnLoaderStatus(true)
    dispatch(createUser({
      name,
      // email,
      password,
      passwordConfirm,
      phone,
  }));
  };

  useEffect(() => {
    if (userData) {
      setBtnLoaderStatus(false)
      if (userData.status === 201) {
        if (userData.data) {
          if (userData.data.token) {
            notifysuccess("تم التسجيل بنجاح");
            navigate('/login')
          }
        }
      } else if (userData.status === 400) {
        if (userData.data) {
          if(userData.data){
            if(userData.data.message){
            notifyError("البريد الالكتروني مسجل من قبل")
            }
            if(userData.data.errors){
              userData.data.errors.map((error)=>(
                notifyError(error.msg)
              ))
            }
          }
        }
      }
    }
  }, [userData]);

  useEffect(() => {
    dispatch(getLoggedUser());
  }, []);

  useEffect(() => {
    if (loggedUserData) {
      if (loggedUserData.status === 200) {
        if (loggedUserData.data) {
          setRole(loggedUserData.data.data.role);
        }
      }
    }
  }, [loggedUserData]);

  return role === "" ? (
    <div className="login-page">
      <Helmet>
        <title>{`SHORTCUT | إنشاء حساب`}</title>
      </Helmet>
      <div className="login-page-wraper">
        <h2>إنشاء حساب</h2>
        <form>
          <label htmlFor="name">
            <span>*</span>اسم المستخدم
          </label>
          <input
            type="name"
            id="name"
            placeholder="أدخل اسم المستخدم"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          {/* <label htmlFor="email-input">
            <span>*</span>البريد الإلكتروني
          </label>
          <input
            type="email"
            id="email-input"
            placeholder="(yourname@gmail.com):أدخل بريدك الإلكتروني مثال"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          /> */}

          <label htmlFor="phone">
            <span>*</span>رقم الهاتف
          </label>
          <input
            type="number"
            id="phone"
            placeholder="أدخل رقم الهاتف"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />

          <label htmlFor="password-input">
            <span>*</span>كلمة المرور
          </label>
          <input
            type="password"
            id="password-input"
            placeholder="أدخل كلمة المرور"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <label htmlFor="confirm-password">
            <span>*</span>تأكيد كلمة المرور
          </label>
          <input
            type="password"
            id="confirm-password"
            placeholder="تأكيد كلمة المرور"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
          />

          <button
            type="submit"
            className="submit"
            onClick={(e) => handelSubmit(e)}
          >
          {
            btnLoaderStatus ? (
              <BtnLoader/>
            ):(
              "إنشاء حساب"
            )
          }
          </button>
        </form>
      </div>
      <div className="signup">
        <Link to="/login">ان كان لديك حساب قم بتسجيل الدخول</Link>
      </div>
    </div>
  ) : (
    navigate("/")
  );
};

export default Signup;
