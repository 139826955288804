import React, { useEffect, useState } from "react";
import Cart from "../../images/svg/cart.svg";
import { addToCart } from "../../redux/actions/cartAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getLoggedUser } from "../../redux/actions/authAction";
import BtnLoader from "../../utilities/BtnLoader";

const Product = ({ product }) => {
  const [singleProduct, setSingleProduct] = useState([]);
  const [selectedSize, setSelectedSize] = useState("");
  const [loggedUser, setLoggedUser] = useState("");
  const [sizeIndex, setSizeIndex] = useState("");
  const [btnLoaderStatus, setBtnLoaderStatus] = useState(false);

  const dispatch = useDispatch();

  const getLogedUserData = useSelector((state) => state.authReducer.getMe);


  const notifyError = (msg) => toast.error(msg);
  const notifySuccess = (msg) => toast.success(msg);

  const handelCart = async (productId) => {
    setBtnLoaderStatus(true)
    if (loggedUser === 401 || loggedUser === 500) {
      notifyError("من فضلك قم بتسجيل الدخول أولاً");
      setBtnLoaderStatus(false)
    } else if (loggedUser === 200) {
      if (selectedSize) {
        await dispatch(
          addToCart({
            productId: productId,
            color: selectedSize,
          })
        );
        notifySuccess("تم إضافة المنتج إلى العربة");
        setBtnLoaderStatus(false)
      } else {
        notifyError("من فضلك قم بتحديد المقاس أولاً");
        setBtnLoaderStatus(false)
      }
    }
  };

  useEffect(() => {
    if (product) {
      setSingleProduct(product);
    }
  }, [product]);

  useEffect(() => {
    dispatch(getLoggedUser());
  }, []);

  useEffect(() => {
    if (getLogedUserData) {
      setLoggedUser(getLogedUserData.status);
    }
  }, [getLogedUserData]);

  return (
    <div className="product">
      <a
        href={singleProduct.availability ? `/product/${singleProduct._id}` : (e)=> e.preventDefault()}
        style={
          singleProduct.availability
            ? { cursor: "pointer" }
            : { cursor: "not-allowed" }
        }
      >
        <div className="product-img">
          <span
            className="sold-out"
            style={
              singleProduct.availability
                ? { display: "none" }
                : { display: "flex" }
            }
          >
            نفذ
          </span>
          <span
            className="sale"
            style={
              singleProduct.sale === ""
                ? { display: "none" }
                : { display: "flex" }
            }
          >
            {singleProduct.sale}
          </span>
          <img
            src={singleProduct.imageCover}
            alt={singleProduct.title}
            loading="lazy"
          />
        </div>
      </a>
      <div className="sizes">
        <ul>
          {singleProduct.availableSizes
            ? singleProduct.availableSizes.map((size, index) => (
                <li
                  key={index}
                  style={
                    sizeIndex === index
                      ? { background: "#000", color: "#f3f3f3" }
                      : { background: "#ccc", color: "#111" }
                  }
                  onClick={(e) => {
                    setSelectedSize(e.target.innerText);
                    setSizeIndex(index);
                  }}
                >
                  {size}
                </li>
              ))
            : null}
        </ul>
      </div>
      <div
        className="add-to-cart"
        onClick={
          singleProduct.availability
            ? () => handelCart(singleProduct._id)
            : (e)=> e.preventDefault()
        }
        style={
          singleProduct.availability
            ? { cursor: "pointer" }
            : { cursor: "not-allowed" }
        }
      >
        {btnLoaderStatus ? (
          <BtnLoader />
        ) : (
          <>
            <img src={Cart} alt="cart" loading="lazy" />
            <h5>{singleProduct.availability ? "!أضف إلى عربة التسوق":"!المنتج غير متوفر"}</h5>
          </>
        )}
      </div>
      <div className="product-details">
        <h3>{singleProduct.title}</h3>
        <div className="product-price">
          <p className="old-price">{singleProduct.price}.00 L.E</p>
          <p className="new-price">{singleProduct.priceAfterDiscount}.00 LE</p>
        </div>
      </div>
    </div>
  );
};

export default Product;
