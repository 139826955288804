import React from 'react'
import { Helmet } from 'react-helmet-async'
const Offers = () => {
  return (
    <div className='offers-page'>
      <Helmet>
        <title>{`SHORTCUT | عروض وخصومات`}</title>
      </Helmet>
      <h2 className='title'>عروض وخصومات شورت كت</h2>
      <div className='offers-container'>
        <div className='offer'>
          <a href="/less100">
          <h3>منتجات أقل من</h3>
          <p className='price'>100</p>
          <p>جنية</p>
          </a>
        </div>

        <div className='offer'>
          <a href="/less150">
          <h3>منتجات أقل من</h3>
          <p className='price'>150</p>
          <p>جنية</p>
          </a>
        </div>

        <div className='offer'>
          <a href="/less200">
          <h3>منتجات أقل من</h3>
          <p className='price'>200</p>
          <p>جنية</p>
          </a>
        </div>

        <div className='offer'>
          <a href="/less250">
          <h3>منتجات أقل من</h3>
          <p className='price'>250</p>
          <p>جنية</p>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Offers