import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getLoggedUser } from "../../redux/actions/authAction";
import { getCartItems } from "../../redux/actions/cartAction";
import { createCashOrder } from "../../redux/actions/orderAction";
import { useNavigate, useSearchParams } from "react-router-dom";
import Check from "../../images/svg/check-white.svg";
import Close from "../../images/svg/close-white.svg";
import Spiner from '../../utilities/Spiner'

import { sha512 } from "js-sha512";
import { Helmet } from "react-helmet-async";
import { shippingPrice } from "../../api/ShippingPrice";

const PaymentStatus = () => {
  const [cartId, setCartId] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [orderPaymentStatus, setOrderPaymentStatus] = useState(false);
  const [paymentPending, setPaymentPending] = useState("");
  const [trnxId, setTrnxId] = useState("456777");
  const [orderPaymobId, setOrderPaymobId] = useState("7474747");
  const [orderPrice, setOrderPrice] = useState("");
  const [paymentHmac, setPaymentHmac] = useState("");
  const [orderShippingdetails, setOrderShippingdetails] = useState({});
  const [loading, setLoading] = useState(true);

  const loggedUserData = useSelector((state) => state.authReducer.getMe);
  const cartUserData = useSelector((state) => state.cartReducer.cartItems);
  const cashOrderData = useSelector((state) => state.orderReducer.cashOrder);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams) {
      setPaymentStatus(searchParams.get("success"));
      setPaymentPending(searchParams.get("pending"));
      setTrnxId(searchParams.get("id"));
      setOrderPaymobId(searchParams.get("order"));
      setPaymentHmac(searchParams.get("hmac"));
      setOrderPaymobId(searchParams.get("order"));

      setCreatedAt(searchParams.get("created_at"));
      setCurrency(searchParams.get("currency"));
      setErrorOccured(searchParams.get("error_occured"));
      setHasParentTransaction(searchParams.get("has_parent_transaction"));
      setIntegrationId(searchParams.get("integration_id"));
      setIs3dSecure(searchParams.get("is_3d_secure"));
      setIsAuth(searchParams.get("is_auth"));
      setIsCapture(searchParams.get("is_capture"));
      setIsRefunded(searchParams.get("is_refunded"));
      setIsStandalonePayment(searchParams.get("is_standalone_payment"));
      setIsVoided(searchParams.get("is_voided"));
      setOwner(searchParams.get("owner"));
      setSourceDataPan(searchParams.get("source_data.pan"));
      setSourceDataSubType(searchParams.get("source_data.sub_type"));
      setSourceDataType(searchParams.get("source_data.type"));

      setOrderPrice(searchParams.get("amount_cents"));
    }
  }, [searchParams]);

  const [createdAt, setCreatedAt] = useState("");
  const [currency, setCurrency] = useState("");
  const [errorOccured, setErrorOccured] = useState("");
  const [hasParentTransaction, setHasParentTransaction] = useState("");
  const [integrationId, setIntegrationId] = useState("");
  const [is3dSecure, setIs3dSecure] = useState("");
  const [isAuth, setIsAuth] = useState("");
  const [isCapture, setIsCapture] = useState("");
  const [isRefunded, setIsRefunded] = useState("");
  const [isStandalonePayment, setIsStandalonePayment] = useState("");
  const [isVoided, setIsVoided] = useState("");
  const [owner, setOwner] = useState("");
  const [sourceDataPan, setSourceDataPan] = useState("");
  const [sourceDataSubType, setSourceDataSubType] = useState("");
  const [sourceDataType, setSourceDataType] = useState("");

  //order data
  const [details, setDetails] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    dispatch(getLoggedUser());
    dispatch(getCartItems());
    setOrderShippingdetails(JSON.parse(localStorage.getItem("shortcutOrder")));
  }, []);

  useEffect(() => {
    if (orderShippingdetails) {
      console.log(orderShippingdetails);
      setDetails(orderShippingdetails.details);
      setCity(orderShippingdetails.shippingCity);
      setPhone(orderShippingdetails.phone);
      setPostalCode(orderShippingdetails.postalCode);
      setUserEmail(orderShippingdetails.userEmail);
    }
  }, [orderShippingdetails]);


  //hmac string from paymob request
  const string = `${orderPrice}${createdAt}${currency}${errorOccured}${hasParentTransaction}${trnxId}${integrationId}${is3dSecure}${isAuth}${isCapture}${isRefunded}${isStandalonePayment}${isVoided}${orderPaymobId}${owner}${paymentPending}${sourceDataPan}${sourceDataSubType}${sourceDataType}${paymentStatus}`;

  //hmac hashed
  const hashed = sha512.hmac("A8AFE1AA8BF51A1501CFC9CB290E29BF", string);


  useEffect(() => {
    if (paymentHmac === hashed) {
      if(paymentStatus === "true"){
        setTimeout(()=>{
          setLoading(false)
        },5000)
        if(sourceDataType === "card"){
          dispatch(
            createCashOrder(cartId, {
              shippingAddress: {
                details,
                phone,
                city,
                postalCode,
              },
              isPaid: true,
              trnxId: trnxId,
              orderPaymobId: orderPaymobId,
              paymentMethodType: "card",
            })
          );
        }else{
          dispatch(
            createCashOrder(cartId, {
              shippingAddress: {
                details,
                phone,
                city,
                postalCode,
              },
              isPaid: true,
              trnxId: trnxId,
              orderPaymobId: orderPaymobId,
              paymentMethodType: "valu",
            })
          );
        }
      }
    }
  }, [
    details,
    phone,
    city,
    userEmail,
    postalCode,
    trnxId,
    orderPaymobId,
    paymentHmac,
    paymentStatus,
    cartId,
    createdAt,
    currency,
    errorOccured,
    hasParentTransaction,
    integrationId,
    is3dSecure,
    isAuth,
    isCapture,
    isRefunded,
    isStandalonePayment,
    isVoided,
    owner,
    sourceDataPan,
    sourceDataSubType,
    sourceDataType,
  ]);

  useEffect(() => {
    if (cashOrderData) {
      if (cashOrderData.status === 201) {
        localStorage.removeItem("shortcutOrder");
        setOrderPaymentStatus(true)
      }
    }
  }, [cashOrderData]);

  useEffect(() => {
    if (cartUserData) {
      if (cartUserData.status === "success") {
        if (cartUserData.data) {
          setCartId(cartUserData.data._id);
        }
      }
    }
  }, [cartUserData]);

  useEffect(() => {
    if (loggedUserData) {
      if (loggedUserData.status === 200) {
        if (loggedUserData.data) {
          if (loggedUserData.data.data.role !== "user" && paymentPending === null) {
            navigate("/");
          }else if(loggedUserData.data.data.role === "user" && paymentPending === null){
            navigate("/");
          }
        }
      } else if (loggedUserData.status === 401) {
        navigate("/");
      }
    }
  }, [loggedUserData]);

  return (
    <div className="checkout-page">
      <Helmet>
        <title>{`SHORTCUT | حالة الطلب`}</title>
      </Helmet>
      <div className="container">
        <div className="payment-status-wraper">
          {
            loading ? (
             <Spiner/>
            ):(
              orderPaymentStatus !== false ? (
                <div className="payment-status success">
                  <div className="payment-status-img success">
                    <img src={Check} alt="success" />
                  </div>
                  <h3>تمت عملية الدفع بنجاح</h3>
                  <p>لقد تم تنفيذ عملية الدفع بنجاح، شكراً لك</p>
                  <div className="payment-info">
                    <ul>
                      <li>
                        <p>{trnxId}</p>
                        :رقم العملية
                      </li>
                      <li>
                        <p>{orderPaymobId}</p>
                        :رقم الطلب
                      </li>
                      <li>
                        <p>{orderPrice / 100 - shippingPrice} جنية</p>
                        :المبلغ
                      </li>
                      <li>
                        <p>{shippingPrice} جنية</p>
                        :مصاريف الشحن
                      </li>
                      <li className="total success">
                        <p>{orderPrice / 100} جنية</p>
                        :الإجمالي
                      </li>
                    </ul>
                  </div>
                  <Link to="/profile" className="btn">
                    تم
                  </Link>
                </div>
              ) : (
                <div className="payment-status faild">
                  <div className="payment-status-img faild">
                    <img src={Close} alt="faild" />
                  </div>
                  <h3>فشل عملية الدفع</h3>
                  <p>لم نستطيع إتمام عملية الدفع، حاول مرة أخرى</p>
                  <Link to="/checkout" className="btn">
                    حاول مرة أخرى
                  </Link>
                </div>
              )
            )
          }
        </div>
      </div>
    </div>
  );
};

export default PaymentStatus;
