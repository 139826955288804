import React from 'react'

const AdOne = () => {
  return (
    <>
    <a href="/less150">
    <div className='ad-one'></div>
    </a>
    </>
  )
}

export default AdOne