import React,{useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Product from '../products/Product'
import { getAllProductsWithFilter } from "../../redux/actions/productAction";

const LookLike = ({productCategory, productId, productAge, productBarcode}) => {
  const [allProducts, setAllProducts] = useState([]);

  const allProductsData = useSelector(
    (state) => state.productReducer.productsFilter
  );

  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(getAllProductsWithFilter(`limit=${10}&category[in][]=${productCategory}&age=${productAge}`))
  },[productCategory, productAge])

  useEffect(()=>{
    if(allProductsData){
      if(allProductsData.status === 200){
        if(allProductsData.data){
          setAllProducts(allProductsData.data.data)
        }
      }
    }
  },[allProductsData])


  const filtered = allProducts.filter(product=> product._id !== productId)

  return (
    <div className='look-like'>
        <h2>أصناف ذات صلة</h2>
        <div className='look-like-wraper'>
          {
            filtered.length > 0 ? (
              filtered.map((product, index)=>(
                <Product key={index} product={product}/>
              ))
            ):null
          }
        </div>
    </div>
  )
}

export default LookLike