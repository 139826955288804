import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Spiner from "../../utilities/Spiner";
import { useNavigate } from "react-router-dom";
import { getLoggedUser } from "../../redux/actions/authAction";
import { getSpecificUser } from "../../redux/actions/usersAction";
import { Helmet } from "react-helmet-async";

const UserDetails = () => {
  const [specificUserDetails, setSpecificUserDetails] = useState([]);
  const [userDetailsLoading, setUserDetailsLoading] = useState([]);

  const { id } = useParams();

  const dispatch = useDispatch();
  const specificUserData = useSelector(
    (state) => state.usersReducer.specificUser
  );
  const loggedUserData = useSelector((state) => state.authReducer.getMe);

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      dispatch(getSpecificUser(id));
    }
  }, [id]);

  useEffect(() => {
    if (specificUserData) {
      if (specificUserData.status === 200) {
        if (specificUserData.data) {
          setSpecificUserDetails(specificUserData.data.data);
          setUserDetailsLoading(false);
        }
      }
    }
  }, [specificUserData]);

  useEffect(() => {
    dispatch(getLoggedUser());
  }, []);

  useEffect(()=>{
    if(loggedUserData){
      if(loggedUserData.status === 200){
        if(loggedUserData.data){
          if(loggedUserData.data.data.role !== "admin"){
            navigate('/')
          }
        }
      }else if(loggedUserData.status === 401){
        navigate('/')
      }
    }
  },[loggedUserData])

  return (
    <div className="order-details-page">
      <Helmet>
        <title>{`SHORTCUT | تفاصيل المُستخدم`}</title>
      </Helmet>
      <div className="container">
        {userDetailsLoading ? (
          <Spiner />
        ) : specificUserDetails ? (
          <div className="order-wraper">
            <h2>تفاصيل بيانات المٌستخدم</h2>
            <div className="order-details-container">
              <div className="order-items"></div>
              <div className="order-details">
                <div className="user-info">
                  <h3>بيانات العميل</h3>
                  <ul>
                    <li>
                      <p>{specificUserDetails.name}</p>
                      :اسم العميل
                    </li>
                    {/* <li>
                      <p>{specificUserDetails.email}</p>
                      :البريد الإلكتروني
                    </li> */}
                    <li>
                      <p>{specificUserDetails.phone}</p>
                      :رقم الهاتف
                    </li>
                    <li>
                      <p>{specificUserDetails.createdAt}</p>
                      :تاريخ التسجيل
                    </li>
                    <li>
                      <p>{specificUserDetails.role}</p>
                      :الصلاحية
                    </li>
                  </ul>
                </div>
                <div className="shipping-info">
                  <h3>عناوين المٌستخدم</h3>
                  {specificUserDetails.addresses.length > 0 ? (
                    specificUserDetails.addresses.map((address, index) => (
                      <ul className="address-list" key={index}>
                        <li>
                          <p>{address.city}</p>
                          :المحافظة
                        </li>
                        <li>
                          <p>{address.details}</p>
                          :العنوان بالتفصيل
                        </li>
                        <li>
                          <p>{address.phone}</p>
                          :رقم الهاتف
                        </li>
                        <li>
                          <p>{address.postalCode}</p>
                          :الرقم البريدي
                        </li>
                      </ul>
                    ))
                  ) : (
                    <p>لا يوجد عناوين مٌسجلة لهذا المٌستخدم</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default UserDetails;
