import React, { useEffect, useState } from "react";

const Product = ({product}) => {

  const [singleProduct, setSingleProduct] = useState([])

  useEffect(()=>{
    if(product){
      setSingleProduct(product)
    }
  },[product])
  

  return (
    <div className="product">
      <a href={`/product/${singleProduct._id}`}>
        <div className="product-img">
          <img src={singleProduct.imageCover} alt={singleProduct.title} loading='lazy'/>
          <div className="add-to-cart admin">
            <a href={`/admin/edit-product/${singleProduct._id}`} className="edit">تعديل</a>
          </div>
        </div>
        <div className="product-details">
          <h3>{singleProduct.title}</h3>
          <div className="product-price">
            <p className="old-price">{singleProduct.price}.00 L.E</p>
            <p className="new-price" style={{color: "#FFF"}}>{singleProduct.priceAfterDiscount}.00 LE</p>
          </div>
        </div>
      </a>
    </div>
  );
};

export default Product;
