import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Facebook from "../images/svg/facebook.svg";
import Instagram from "../images/svg/instagram.svg";
import Tiktok from "../images/svg/tiktok.svg";
import Youtube from "../images/svg/youtube.svg";
import Plus from "../images/svg/plus.svg";
import Minus from "../images/svg/minus-white.svg";
import Home from "../images/svg/home.svg";
import User from "../images/svg/user.svg";
import Cart from "../images/svg/cart.svg";
import Offer from "../images/svg/offer.svg";
import Cash from "../images/imgs/icon-1.avif";
import Value from "../images/imgs/icon-2.webp";
import Primum from "../images/imgs/icon-3.avif";
import Visa from "../images/imgs/visa.png";
import MasterCard from "../images/imgs/MasterCard.png";
import Vodafon from "../images/imgs/vodafone-cash.png";

import {useDispatch, useSelector} from 'react-redux'
import { getCartItems } from "../redux/actions/cartAction";

const Footer = () => {
  const [client, setClient] = useState(false);
  const [about, setAbout] = useState(false);
  const [contact, setContact] = useState(false);
  const [aboutplusImg, setAboutPlusImg] = useState(true);
  const [contactplusImg, setContactPlusImg] = useState(true);
  const [clientplusImg, setClientPlusImg] = useState(true);

  const [numOfCartItems, setNumOfCartItems] = useState("");
  const cartItemsData = useSelector(state=> state.cartReducer.cartItems)
  const dispatch = useDispatch()

  useEffect(()=>{
      if(localStorage.getItem("userToken")){
        dispatch(getCartItems())
      }
    },[])

    useEffect(()=>{
      if(cartItemsData){
        if(cartItemsData.status === "success"){
          setNumOfCartItems(cartItemsData.numOfCartItems)
        }
      }
    },[cartItemsData])

  return (
    <footer className="footer">
      <div className="footer-wraper">
        <div className="form-wraper">
          <h3>اشترك في قائمة شورت كت البريدية</h3>
          <p>ليصلك كل عروضنا الحصرية على بريدك الإلكتروني</p>
          <form>
            <button>أشترك</button>
            <input type="text" placeholder="أدخل بريدك الإلكتروني" />
          </form>
        </div>
        <div className="info-wraper">
          <div className="info-col">
            <h5 className="social-media">كن علي تواصل</h5>
            <div className="links social-group">
              <a href="https://www.facebook.com/shortcutmenwear" target="blanck" className="link">
                <img src={Facebook} alt="facebook" />
              </a>
              <a href="https://www.instagram.com/shortcutmenswear/" target="blanck" className="link">
                <img src={Instagram} alt="facebook" />
              </a>
              <a href="https://www.tiktok.com/@shortcut1mens" target="blanck" className="link">
                <img src={Tiktok} alt="facebook" />
              </a>
              <Link to="/" className="link">
                <img src={Youtube} alt="facebook" />
              </Link>
            </div>
            <h5 className="social-media">وسائل الدفع</h5>
            <div className="links social-group payment">
              <Link to="/" className="link">
                <img src={Cash} alt="facebook" />
              </Link>
              <Link to="/" className="link">
                <img src={Value} alt="facebook" />
              </Link>
              <Link to="/" className="link">
                <img src={Primum} alt="facebook" />
              </Link>
              <Link to="/" className="link">
                <img src={Visa} alt="facebook" />
              </Link>
              <Link to="/" className="link">
                <img src={MasterCard} alt="facebook" />
              </Link>
              <Link to="/" className="link">
                <img src={Vodafon} alt="facebook" />
              </Link>
            </div>
          </div>
          <div className="info-col">
            <h5 onClick={() =>{
              setContact(!contact)
              setContactPlusImg(!contactplusImg)
            }}>
              <img src={contactplusImg ? Plus : Minus} alt="plus" />
              تواصل معنا
            </h5>
            <div className={`links ${contact && "show"}`}>
              <Link to="/" className="link">
                أتصل بنا
              </Link>
            </div>
            <div className={`links ${contact && "show"}`}>
              <a href="https://wa.me/201111102271" target="_blank" className="link">
                لطلبات الجملة
              </a>
            </div>
          </div>
          <div className="info-col">
            <h5 onClick={() =>{
              setAbout(!about)
              setAboutPlusImg(!aboutplusImg)
            }}>
              <img src={aboutplusImg ? Plus : Minus} alt="plus" />
              عنا
            </h5>
            <div className={`links ${about && "show"}`}>
              <Link to="/about-shortcut" className="link">
                عن شورت كت
              </Link>
              <Link to="/" className="link">
                فروعنا
              </Link>
            </div>
          </div>
          <div className="info-col">
            <h5 onClick={() =>{
              setClient(!client)
              setClientPlusImg(!clientplusImg)
            }}>
              <img src={clientplusImg ? Plus : Minus} alt="plus"/>
              خدمة العملاء
            </h5>
            <div className={`links ${client && "show"}`}>
              <Link to="/terms-and-condition" className="link">
                الشروط والأحكام
              </Link>
              <Link to="/team-privacy-policy" className="link">
                سياسة الخصوصية
              </Link>
              <Link to="/delivery-and-returns" className="link">
                التوصيل والمرتجع
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="end-footer">
        <p>
          <a href="https://www.facebook.com/omar.zezo.5268" target="blanck">Omar Abd Elaziz</a> برمجة وتصميم
        </p>
        <p>جميع الحقوق محفوظة شورت كت &#169; 2024</p>
      </div>
      <div className='footer-navigation'>
                <ul>
                    <li>
                        <Link to={"/"}><img src={Home} alt='home'/></Link>
                    </li>
                    <li>
                        <Link to={"/offers"}><img src={Offer} alt='home'/></Link>
                    </li>
                    <li>
                        <Link to={"/cart"}><img src={Cart} alt='home'/></Link>
                        <span style={numOfCartItems ? {display:"flex"}:{display:"none"}}>{numOfCartItems ? numOfCartItems: 0}</span>
                    </li>
                    <li>
                        {
                            localStorage.getItem("token") ? (
                                <Link to="/profile"><img src={User} alt='home'/></Link>
                            ):(
                                <Link to="/login"><img src={User} alt='home'/></Link>
                            )
                        }
                        {/* <Link to={localStorage.getItem("userToen") ? "/profile":"/login"}><img src={User} alt='home'/></Link> */}
                    </li>
                </ul>
            </div>
    </footer>
  );
};

export default Footer;
