import { Routes, Route } from "react-router-dom";
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet-async';
import Home from "./pages/Home";
import CategoryPage from "./pages/CategoryPage";
import Navbar from "./utilities/Navbar";
import ProductPage from "./pages/ProductPage";
import ScrollToTop from "./utilities/ScrollToTop";
import Footer from "./utilities/Footer";
import Login from "./pages/accounts/Login";
import Signup from "./pages/accounts/Signup";
import Profile from "./pages/accounts/Profile";
import ManageProducts from "./pages/admin/ManageProducts";
import AddCategory from "./pages/admin/AddCategory";
import AddBrand from "./pages/admin/AddBrand";
import AddProduct from "./pages/admin/AddProduct";
import Cart from "./pages/orders/Cart";
import AddCoupon from "./pages/admin/AddCoupon";
import ManageCoupons from "./pages/admin/ManageCoupons";
import AddAddress from "./pages/accounts/AddAddress";
import Checkout from "./pages/orders/Checkout";
import ManageOrders from "./pages/admin/ManageOrders";
import OrderDetails from "./pages/admin/OrderDetails";
import EditProduct from "./pages/admin/EditProduct";
import SearchPage from "./pages/SearchPage";
import ForgotPassword from "./pages/accounts/ForgotPassword";
import ResetCode from "./pages/accounts/ResetCode";
import ResetPassword from "./pages/accounts/ResetPassword";
import ManageUsers from "./pages/admin/ManageUsers";
import UserDetails from "./pages/admin/UserDetails";
import TermsAndCondition from "./pages/static/TermsAndCondition";
import TeamPrivacyPolicy from "./pages/static/TeamPrivacyPolicy";
import DeliveryAndReturns from "./pages/static/DeliveryAndReturns";
import Aboutus from "./pages/static/Aboutus";
import WinterCollection from "./pages/WinterCollection";
import KidsWinterCollection from "./pages/KidsWinterCollection";
import MenWinterCollection from "./pages/MenWinterCollection";
import NewArrived from "./pages/NewArrived";
import ManageCategories from "./pages/admin/ManageCategories";
import Less100 from "./pages/Less100";
import Less200 from "./pages/Less200";
import SummerCollection from "./pages/SummerCollection";
import MenSummerCollection from "./pages/MenSummerCollection";
import Offers from "./pages/Offers";
import Toastify from "./utilities/Toastify";
import EditCategory from "./pages/admin/EditCategory";
import PaymentStatus from "./pages/orders/PaymentStatus";
import Less150 from "./pages/Less150";
import KidsSummerCollection from "./pages/KidsSummerCollection";
import Less250 from "./pages/Less250";
import Soldout from "./pages/Soldout";

function App({title}) {
  ReactGA.initialize("G-DHL99CMKQL");
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });

  return (
    <div className="App">
      <Helmet>
        <title>SHORTCUT | الصفحة الرئيسية</title>
      </Helmet>
      <Toastify />
      <ScrollToTop>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/category/:id" element={<CategoryPage />} />
          <Route path="/winter" element={<WinterCollection />} />
          <Route path="/summer" element={<SummerCollection />} />
          <Route path="/offers" element={<Offers />} />
          <Route
            path="/winter-kids-collection"
            element={<KidsWinterCollection />}
          />
          <Route
            path="/summer-kids-collection"
            element={<KidsSummerCollection />}
          />
          <Route
            path="/winter-men-collection"
            element={<MenWinterCollection />}
          />
          <Route
            path="/summer-men-collection"
            element={<MenSummerCollection />}
          />
          <Route path="/new-arrived" element={<NewArrived />} />
          <Route path="/less100" element={<Less100 />} />
          <Route path="/less150" element={<Less150 />} />
          <Route path="/less200" element={<Less200 />} />
          <Route path="/less250" element={<Less250 />} />

          <Route path="/soldout" element={<Soldout />} />

          <Route path="/product/:id" element={<ProductPage />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-code" element={<ResetCode />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/add-address" element={<AddAddress />} />
          <Route path="/admin" element={<ManageProducts />} />
          <Route path="/order-details/:id" element={<OrderDetails />} />
          <Route path="/user-details/:id" element={<UserDetails />} />
          <Route path="/admin/add-category" element={<AddCategory />} />
          <Route path="/admin/edit-category/:id" element={<EditCategory />} />
          <Route path="/admin/add-brand" element={<AddBrand />} />
          <Route path="/admin/add-product" element={<AddProduct />} />
          <Route path="/admin/edit-product/:id" element={<EditProduct />} />
          <Route path="/admin/add-coupon" element={<AddCoupon />} />
          <Route path="/admin/manage-coupons" element={<ManageCoupons />} />
          <Route
            path="/admin/manage-categories"
            element={<ManageCategories />}
          />
          <Route path="/admin/manage-users" element={<ManageUsers />} />
          <Route path="/admin/manage-orders" element={<ManageOrders />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/payment-status" element={<PaymentStatus />} />

          <Route path="/terms-and-condition" element={<TermsAndCondition />} />
          <Route path="/team-privacy-policy" element={<TeamPrivacyPolicy />} />
          <Route
            path="/delivery-and-returns"
            element={<DeliveryAndReturns />}
          />
          <Route path="/about-shortcut" element={<Aboutus />} />
        </Routes>
        <Footer />
      </ScrollToTop>
    </div>
  );
}

export default App;
