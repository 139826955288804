import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Sidebar from "../../utilities/Sidebar";
import ProductAdmin from "../../components/products/ProductAdmin";
import { getAllProductsWithFilter } from "../../redux/actions/productAction";
import Spiner from "../../utilities/Spiner";
import { useNavigate } from "react-router-dom";
import { getLoggedUser } from "../../redux/actions/authAction";
import Pagination from "../../utilities/Pagination";
import { Helmet } from "react-helmet-async";

const ManageProducts = () => {
  const [allProducts, setAllProducts] = useState([]);
  const [pageResult, setPageResult] = useState([]);
  const [pageNumber, setPageNumber] = useState("")
  const [allProductsLoading, setAllProductsLoading] = useState(true);

  const products = useSelector((state) => state.productReducer.productsFilter);
  const loggedUserData = useSelector(state=>state.authReducer.getMe)

  const navigate = useNavigate()

  const dispatch = useDispatch();

  let limit = 20

  useEffect(() => {
    dispatch(getLoggedUser())
    dispatch(getAllProductsWithFilter(`limit=${limit}`));
  }, []);

  useEffect(() => {
    if (products) {
      if (products.data) {
        setPageResult(products.data.paginationResult)
        setAllProducts(products.data.data);
        setAllProductsLoading(false);
      }
    }
  }, [products]);

  useEffect(()=>{
    if(loggedUserData){
      if(loggedUserData.status === 200){
        if(loggedUserData.data){
          if(loggedUserData.data.data.role !== "admin"){
            navigate('/')
          }
        }
      }else if(loggedUserData.status === 401){
        navigate('/')
      }
    }
  },[loggedUserData])

  useEffect(()=>{
    dispatch(getAllProductsWithFilter(`limit=${limit}&page=${pageNumber}`));
  }, [pageNumber])

  //to get page number
  const getPage = (pageNumber)=>{
    setPageNumber(pageNumber)
  }

  return (
    <div className="admin-page">
      <Helmet>
        <title>{`SHORTCUT | إدارة المُنتجات`}</title>
      </Helmet>
      <div className="container">
        <div className="admin-page-wraper">
          <Sidebar />
          <section className="dashboard-container">
            <h3>إدارة المُنتجات</h3>
            <div className="add-wraper">
              <div className="products-container">
                {allProductsLoading ? (
                  <Spiner />
                ) : allProducts ? (
                  allProducts.map((product, index) => (
                    <ProductAdmin key={index} product={product} />
                  ))
                ) : null}
              </div>
            </div>
            <div className="pagnation-container">
              <Pagination pageResult={pageResult} onPress={getPage}/>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ManageProducts;
