import React,{useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Intro from '../components/Home/Intro'
import FreeShipping from '../components/Home/FreeShipping'
import Categories from '../components/Home/Categories'
import AdOne from '../components/Home/AdOne'
import Services from '../components/Home/Services'
import ProductHomePage from '../components/Home/ProductHomePage'
// import KidsCollection from '../components/Home/KidsCollection'
import { getAllProductsWithFilter, getAllProducts } from "../redux/actions/productAction";

const Home = () => {
  const [allProducts, setAllProducts] = useState([]);
  const [bestSellerProducts, setBestSellerProducts] = useState([]);

  const allProductsData = useSelector(
    (state) => state.productReducer.products
  );

  const bestSellerProductsData = useSelector(
    (state) => state.productReducer.productsFilter
  );

  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(getAllProducts(`limit=${200}`))
  },[])


  useEffect(()=>{
    dispatch(getAllProductsWithFilter(`season="شتاء"&bestSeller=${true}`))
  },[])

  useEffect(()=>{
    if(allProductsData){
      if(allProductsData.status === 200){
        if(allProductsData.data){
          setAllProducts(allProductsData.data.data)
        }
      }
    }
  },[allProductsData])

  useEffect(()=>{
    if(bestSellerProductsData){
      if(bestSellerProductsData.status === 200){
        if(bestSellerProductsData.data){
          setBestSellerProducts(bestSellerProductsData.data.data)
        }
      }
    }
  },[bestSellerProductsData])

  
  const pantsList = [
    "664632b824be2fd342d3834b",
    "664631b624be2fd342d3832c",
    "6646315424be2fd342d382f5",
    "6646346824be2fd342d383a2",
    "6646381e24be2fd342d384de"
  ]

  const jacketList = [
    "671b76031b9a26c51f9c1b91",
    "671b764c1b9a26c51f9c1bbf",
    "671b768f1b9a26c51f9c1be0",
    "671b774d1b9a26c51f9c1c30",
    "671b77161b9a26c51f9c1c23",
    "671b76d71b9a26c51f9c1c16"
  ]

  const newArrivedList = [
    "671a580c1b9a26c51f9c0d6a",
    "671ebb361b9a26c51f9c4f2d",
    "671ebad81b9a26c51f9c4eec",
    "671a5a8c1b9a26c51f9c0fe9",
    "671a5b221b9a26c51f9c102b",
    "671a5a461b9a26c51f9c0fb8",
  ]

  const pants = allProducts.filter(product=> pantsList.includes(product._id))
  const jacket = allProducts.filter(product=> jacketList.includes(product._id))
  const newArrived = allProducts.filter(product=> newArrivedList.includes(product._id))

  return (
    <>
        <Intro/>
        <FreeShipping/>
        <AdOne/>
        {/* <ProductHomePage title="المنتجات الأكثر مبيعاً" products={bestSellerProducts}/> */}
        <ProductHomePage title="وصل حديثاً" products={newArrived}/>

        <Categories/>
        <ProductHomePage title="كوليكشن الجاكت" products={jacket}/>
        <ProductHomePage title="كوليكشن البنطلون" products={pants}/>
        {/* <KidsCollection/> */}
        <Services/>
    </>
  )
}

export default Home