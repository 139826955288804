import baseURL from '../../api/baseURL'
import {GET_ALL_USERS, DELETE_USER_ADMIN, GET_SPECIFIC_USER} from '../types'

export const getAllUsers = (str)=> async(dispatch)=>{
    try{
        const config = {headers:{Authorization: `Bearer ${localStorage.getItem("token")}`}}
        const res = await baseURL.get(`/api/v1/users?${str}`, config)
        dispatch({type: GET_ALL_USERS, payload: res})
    }catch(e){
        dispatch({type: GET_ALL_USERS, payload: e.response})
    }
}

export const deleteUserAdmin = (id)=> async(dispatch)=>{
    try{
        const config = {headers:{Authorization: `Bearer ${localStorage.getItem("token")}`}}
        const res = await baseURL.delete(`/api/v1/users/${id}`, config)
        dispatch({type: DELETE_USER_ADMIN, payload: res})
    }catch(e){
        dispatch({type: DELETE_USER_ADMIN, payload: e.response})
    }
}


export const getSpecificUser = (id)=> async(dispatch)=>{
    try{
        const config = {headers:{Authorization: `Bearer ${localStorage.getItem("token")}`}}
        const res = await baseURL.get(`/api/v1/users/${id}`, config)
        dispatch({type: GET_SPECIFIC_USER, payload: res})
    }catch(e){
        dispatch({type: GET_SPECIFIC_USER, payload: e.response})
    }
}