import React, { useEffect, useState } from "react";
import Product from "../components/products/Product";
import { useDispatch, useSelector } from "react-redux";
import { getAllProductsWithFilter } from "../redux/actions/productAction";
import Spiner from "../utilities/Spiner";
import Pagination from "../utilities/Pagination";
import { Helmet } from "react-helmet-async";

const MenSummerCollection = () => {
  const dispatch = useDispatch();

  const products = useSelector((state) => state.productReducer.productsFilter);

  const [allProducts, setAllProducts] = useState([]);
  const [productLoading, setProductLoading] = useState(true);

  const [pageNumber, setPageNumber] = useState("");
  const [pageResult, setPageResult] = useState("");

  let limit = 20


  //get all products
  useEffect(() => {
    dispatch(getAllProductsWithFilter(`season=صيف&age=رجال&limit=${limit}`));
  }, []);

  useEffect(() => {
    if (products) {
      if (products.data) {
        setAllProducts(products.data.data);
        setPageResult(products.data.paginationResult)
        setProductLoading(false);
      }
    }
  }, [products]);


  useEffect(()=>{
    dispatch(getAllProductsWithFilter(`season=صيف&age=رجال&limit=${limit}&page=${pageNumber}`));
  }, [pageNumber])

  //to get page number
  const getPage = (pageNumber)=>{
    setProductLoading(true)
    setPageNumber(pageNumber)
  }


  return (
    <div className="category-page">
      <Helmet>
        <title>{`SHORTCUT | منتجات الصيف رجالي`}</title>
      </Helmet>
      <div className="container">
        {productLoading ? (
          <Spiner />
        ) : (
          <div className="category-page-wraper">
            <div className="products">
              <div className="cat-title">
                <h2>
                  <span>({allProducts.length})</span>
                  رجالي
                </h2>
              </div>
              <div className="products-wraper">
                {allProducts.length > 0 ? (
                  allProducts.map((product, index) => (
                    <Product key={index} product={product} />
                  ))
                ) : (
                    <p className="no-items">لا يوجد منتجات حتى الأن</p>
                )}
              </div>
            </div>
            <div className="filter">
              <div className="category-type">
                <h2>التصنيفات</h2>
                <ul>
                  {/* {allCategories.length > 0
                    ? allCategories.map((cat, index) => (
                        <li key={index}>
                          <Link to={`/category/${cat._id}`}>{cat.name}</Link>
                        </li>
                      ))
                    : null} */}
                </ul>
              </div>
              <div className="category-type">
                <h2>الفئة</h2>
                <ul>

                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="pagnation-container">
        <Pagination pageResult={pageResult} onPress={getPage}/>
      </div>
    </div>
  );
};

export default MenSummerCollection;
