import baseURL from '../../api/baseURL'
import {CREATE_CASH_ORDER, GET_ALL_ORDERS, GET_SPECIFIC_ORDER, PAYMENT_STATUS, DELIVER_STATUS, ONLINE_PAYMENT} from '../types'


export const createCashOrder = (id, data)=> async(dispatch)=>{
    try{
        const config = {
            headers:{Authorization: `Bearer ${localStorage.getItem("token")}`}
        }
        const res = await baseURL.post(`/api/v1/orders/${id}`, data, config)
        dispatch({type: CREATE_CASH_ORDER, payload: res})
    }catch(e){
        dispatch({type: CREATE_CASH_ORDER, payload: e.response})
    }
}

export const createOnlineOrder = (cartId, data)=> async(dispatch)=>{
    try{
        const config = {
            headers:{Authorization: `Bearer ${localStorage.getItem("token")}`}
        }
        const res = await baseURL.get(`/api/v1/orders/checkout-session/${cartId}`, config, data)
        dispatch({type: ONLINE_PAYMENT, payload: res})
    }catch(e){
        dispatch({type: ONLINE_PAYMENT, payload: e.response})
    }
}


export const getAllOrders = (str)=> async(dispatch)=>{
    try{
        const config = {
            headers:{Authorization: `Bearer ${localStorage.getItem("token")}`}
        }
        const res = await baseURL.get(`/api/v1/orders?${str}`, config)
        dispatch({type: GET_ALL_ORDERS, payload: res})
    }catch(e){
        dispatch({type: GET_ALL_ORDERS, payload: e.response})
    }
}

export const getSpecificOrder = (id)=> async(dispatch)=>{
    try{
        const config = {
            headers:{Authorization: `Bearer ${localStorage.getItem("token")}`}
        }
        const res = await baseURL.get(`/api/v1/orders/${id}`, config)
        dispatch({type: GET_SPECIFIC_ORDER, payload: res})
    }catch(e){
        dispatch({type: GET_SPECIFIC_ORDER, payload: e.response})
    }
}

export const ChangePaymentStatus = (id)=> async(dispatch)=>{
    try{
        const config = {
            headers:{Authorization: `Bearer ${localStorage.getItem("token")}`}
        }
        const res = await baseURL.put(`/api/v1/orders/${id}/pay`, {},config)
        dispatch({type: PAYMENT_STATUS, payload: res})
    }catch(e){
        dispatch({type: PAYMENT_STATUS, payload: e.response})
    }
}


export const ChangeDeliverStatus = (id)=> async(dispatch)=>{
    try{
        const config = {
            headers:{Authorization: `Bearer ${localStorage.getItem("token")}`}
        }
        const res = await baseURL.put(`/api/v1/orders/${id}/deliver`, {},config)
        dispatch({type: DELIVER_STATUS, payload: res})
    }catch(e){
        dispatch({type: DELIVER_STATUS, payload: e.response})
    }
}