import {GET_ALL_BRAND, CREATE_BRAND} from '../types'

const initState = {
    brands: []
}

const categoryReducer = (state = initState, action)=>{
    switch(action.type){
        case GET_ALL_BRAND:
            return {brands: action.payload}
        case CREATE_BRAND:
            return {brands: action.payload}    
        default:
            return state    
    }
}

export default categoryReducer