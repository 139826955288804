import React from 'react'
import {useDispatch} from 'react-redux'
import Cart from '../../images/svg/cart.svg'
import Trash from '../../images/svg/trash.svg'
import {removeProductFromWishlist} from '../../redux/actions/wishlistAction'
import { addToCart } from "../../redux/actions/cartAction";

import serverUrl from '../../api/serverUrl'

const FavProduct = ({favProduct}) => {
    const dispatch = useDispatch()

    const removeFavProduct = ()=>{
        dispatch(removeProductFromWishlist(favProduct._id))
        window.location.reload()
    }

    const handelCart = async() => {
        await dispatch(
          addToCart({
            productId: favProduct._id,
          })
        );
        removeFavProduct()
      };

  return (
    <div className='fav-product'>
        <img className='trash' src={Trash} alt='trash' onClick={removeFavProduct}/>
        <div className='product-details'>
            <h3>{favProduct.title}</h3>
            <div className='product-price'>
                <p className='old-price'>LE {favProduct.price}.00</p>
                <p className='new-price'>LE {favProduct.priceAfterDiscount}.00</p>
            </div>
            <div className='add-to-cart' onClick={handelCart}>
                <img src={Cart} alt='cart' loading='lazy'/>
                <h5>إضافة لعربة التسوق</h5>
            </div>
        </div>
        <div className='product-img'>
            <img src={favProduct && `${serverUrl}/products/${favProduct.imageCover}`} alt='product' loading='lazy'/>
        </div>
    </div>
  )
}

export default FavProduct