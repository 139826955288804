import React,{useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../utilities/Sidebar";
import Delete from "../../images/svg/close.svg";
import Edit from "../../images/svg/edit.svg";
import {
  deleteCategory,
  getAllCategory,
} from "../../redux/actions/categoryAction";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const ManageCategories = () => {
  const [allCategories, setAllCategories] = useState([]);

  const dispatch = useDispatch();
  const allCategoriesData = useSelector(
    (state) => state.categoryReducer.categories
  );

  useEffect(() => {
    dispatch(getAllCategory());
  }, []);

  useEffect(() => {
    if(allCategoriesData){
      if(allCategoriesData.status === 200){
        if(allCategoriesData.data){
            setAllCategories(allCategoriesData.data.data)
        }
    }
    }
  }, [allCategoriesData]);

  return (
    <div className="admin-page">
       <Helmet>
        <title>{`SHORTCUT | إدارة التصنيفات`}</title>
      </Helmet>
      <div className="container">
        <div className="admin-page-wraper">
          <Sidebar />
          <section className="dashboard-container">
            <h3>إدارة التصنيفات</h3>
            <div className="manage-categories">
              {allCategories.length > 0
                ? allCategories.map((cat, index) => (
                    <div className="category" key={index}>
                      <img
                        src={Delete}
                        alt="delete-category"
                        onClick={() => {
                          dispatch(deleteCategory(cat._id));
                          window.location.reload();
                        }}
                      />
                      <Link to={`/admin/edit-category/${cat._id}`}>
                        <img src={Edit} className="edit" alt="edit-category" />
                      </Link>
                      <h6>{cat.name}</h6>
                    </div>
                  ))
                : null}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ManageCategories;
