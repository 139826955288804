import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Img1 from "../../images/imgs/slide-1.webp";
import Img2 from "../../images/imgs/slide-2.webp";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation, Autoplay } from "swiper/modules";

const slides = [
  {
    img: Img1,
    link: "/category/65f39039d61a55ad3b29e12f",
  },
  {
    img: Img2,
    link: "/category/671a538f1b9a26c51f9c092d",
  }
];

const Intro = () => {
  return (
    <div className="intro">
      <Swiper
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: true,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <a href={slide.link}>
              <img src={slide.img} alt={`img-slide-${index}`} />
              {/* <div className='overlay'></div> */}
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
      {/* <video src={IntroVideo} muted loop autoPlay />  */}
    </div>
  );
};

export default Intro;
