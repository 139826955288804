import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import ReactImageMagnify from "react-image-magnify";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper/modules";

const Slider = function App({ images, imgCover }) {
  return (
    <>
      <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
        <SwiperSlide>
          <ReactImageMagnify
            {...{
              enlargedImagePosition: "over",
              smallImage: {
                alt: "img-product-cover",
                src: imgCover,
                width: 417,
                height: 555,
              },
              largeImage: {
                src: imgCover,
                width: 1200,
                height: 1800,
              },
            }}
          />
          {/* <img src={imgCover} alt={`img-product-cover`} loading="lazy" /> */}
        </SwiperSlide>
        {images
          ? images.map((img, index) => (
              <SwiperSlide key={index}>
                <ReactImageMagnify
                  {...{
                    enlargedImagePosition: "over",
                    smallImage: {
                      alt: `img-${index}`,
                      src: img,
                      width: 417,
                      height: 555,
                    },
                    largeImage: {
                      src: img,
                      width: 1200,
                      height: 1800,
                    },
                  }}
                />
                {/* <img src={img} alt={`img-${index}`} loading="lazy" /> */}
              </SwiperSlide>
            ))
          : null}
      </Swiper>
    </>
  );
};

export default Slider;
