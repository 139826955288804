import React,{useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getAllCategory} from '../../redux/actions/categoryAction'
import Spiner from '../../utilities/Spiner'


const Categories = () => {
    const dispatch = useDispatch()
    const categories = useSelector(state=> state.categoryReducer.categories)

    const [allCategories, setAllCategories] = useState([])

    useEffect(()=>{
        dispatch(getAllCategory())
    },[])

    useEffect(()=>{
        if(categories){
            if(categories.status === 200){
                if(categories.data){
                    setAllCategories(categories.data.data)
                }
            }
        }
    },[categories])

    const categoriesList = ["جاكت","سويت شيرت","فيست"]

    const allCategoriesFilter = allCategories.filter(cat=> categoriesList.includes(cat.name))

  return (
    <section className='categories'>
        <div className='categories-wraper'>
            {
                allCategoriesFilter.length > 0 ? (
                    allCategoriesFilter.slice(0, 3).map((cat, index)=>(
                    <div className='cat' key={index}>
                        <a href={`/category/${cat._id}`}>
                        <img src={cat.image} alt={cat.name} loading='lazy'/>
                        </a>
                        <a href={`/category/${cat._id}`} className='shop-now'>تسوق الأن</a>
                    </div>
                    ))
                ):<Spiner/>
            }
        </div>
    </section>
  )
}

export default Categories