import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Upload from "../../images/svg/upload-img.svg";
import Sidebar from "../../utilities/Sidebar";
import {editCategory, getSingleCategory} from "../../redux/actions/categoryAction";
import { useNavigate, useParams } from "react-router-dom";
import { getLoggedUser } from "../../redux/actions/authAction";
import { Helmet } from "react-helmet-async";

const EditCategory = () => {
  const [imgURL, setImgURL] = useState(Upload);
  const [img, setImg] = useState(null);
  const [categoryName, setCategoryName] = useState("");
  const [catID, setCatID] = useState("");

  const dispatch = useDispatch();

  const loggedUserData = useSelector((state) => state.authReducer.getMe);
  const singleCategoryData = useSelector((state) => state.categoryReducer.singleCategory);
  const editCategoryData = useSelector((state) => state.categoryReducer.editCategory);

  const navigate = useNavigate();
  const {id} = useParams()

  useEffect(() => {
    if(id){
        setCatID(id);
    }
  }, [id]);

  const onChangeImg = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImgURL(URL.createObjectURL(e.target.files[0]));
      setImg(e.target.files[0]);
    }
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    await formData.append("image", img);
    await dispatch(editCategory(formData, catID));
    setImgURL(Upload);
    setCategoryName("");
    // window.location.reload();
  };

  useEffect(() => {
    dispatch(getLoggedUser());
  }, []);

  useEffect(() => {
    if(catID){
        dispatch(getSingleCategory(catID))
    }
  }, [catID]);


  useEffect(()=>{
    if(singleCategoryData){
        if(singleCategoryData.data){
            setCategoryName(singleCategoryData.data.name)
        }
    }
  },[singleCategoryData])


  useEffect(()=>{
    if(editCategoryData){
        console.log(editCategoryData)
        // if(editCategoryData.data){
        //     setCategoryName(singleCategoryData.data.name)
        // }
    }
  },[editCategoryData])


  useEffect(() => {
    if (loggedUserData) {
      if (loggedUserData.status === 200) {
        if (loggedUserData.data) {
          if (loggedUserData.data.data.role !== "admin") {
            navigate("/");
          }
        }
      } else if (loggedUserData.status === 401) {
        navigate("/");
      }
    }
  }, [loggedUserData]);

  return (
    <div className="admin-page">
       <Helmet>
        <title>{`SHORTCUT | تعديل التصنيف`}</title>
      </Helmet>
      <div className="container">
        <div className="admin-page-wraper">
          <Sidebar />
          <section className="dashboard-container">
            <h3>تعديل تصنيف</h3>
            <div className="add-wraper">
              <div className="upload-wraper">
                <label htmlFor="upload-img">
                  <img src={imgURL} alt="upload-img" loading="lazy"/>
                </label>
                <input type="file" id="upload-img" onChange={onChangeImg} />
              </div>
              <form>
                <input
                  type="text"
                  placeholder="اسم التصنيف"
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                />
                <input
                  className="submit"
                  type="submit"
                  value="تعديل تصنيف"
                  onClick={(e) => handelSubmit(e)}
                />
              </form>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default EditCategory;
