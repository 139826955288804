import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
// import {
//   getAllAddAddress,
//   getSpecificAddress,
// } from "../../redux/actions/addressAction";
import { getLoggedUser } from "../../redux/actions/authAction";
import { getCartItems } from "../../redux/actions/cartAction";
import { createCashOrder } from "../../redux/actions/orderAction";
import serverUrl from "../../api/serverUrl";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BtnLoader from "../../utilities/BtnLoader";
import { Helmet } from "react-helmet-async";
import { shippingPrice } from "../../api/ShippingPrice";

const Checkout = () => {
  // const [allUserAddress, setAllUserAddress] = useState([]);
  // const [specificAddressInfo, setSpecificAddressInfo] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [cartId, setCartId] = useState([]);
  const [totalCartPrice, setTotalCartPrice] = useState("");
  const [totalAfterDiscount, setTotalAfterDiscount] = useState("");

  // const userAddressData = useSelector(
  //   (state) => state.addressReducer.allAddress
  // );
  // const specificAddressData = useSelector(
  //   (state) => state.addressReducer.specificAddress
  // );
  const loggedUserData = useSelector((state) => state.authReducer.getMe);
  const cartUserData = useSelector((state) => state.cartReducer.cartItems);
  const cashOrderData = useSelector((state) => state.orderReducer.cashOrder);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const notifyError = (msg) => toast.error(msg);
  const notifySuccess = (msg) => toast.success(msg);

  //order data
  const [details, setDetails] = useState("");
  const [phone, setPhone] = useState("");
  const [shippingCity, setShippingCity] = useState("");
  // const [userEmail, setUserEmail] = useState("");
  const [username, setUsername] = useState("");
  // const [postalCode, setPostalCode] = useState("");
  const [btnLoaderStatus, setBtnLoaderStatus] = useState(false);


  //function to send order
  const handelSendOrder = async () => {
    if (details !== "" && phone !== "" && shippingCity !== "") {
      setBtnLoaderStatus(true);
      await dispatch(
        createCashOrder(cartId, {
          shippingAddress: {
            details,
            phone,
            shippingCity,
            postalCode: "4456",
          },
        })
      );
    } else {
      notifyError("من فضلك قم بإستكمال جميع البيانات");
    }
  };

  useEffect(() => {
    if (cashOrderData) {
      setBtnLoaderStatus(false);
      if (cashOrderData.status === 201) {
        notifySuccess("تم إرسال الطلب بنجاح");
        setTimeout(() => {
          window.location = "/profile";
        }, 3000);
      }
    }
  }, [cashOrderData]);


  useEffect(() => {
    // dispatch(getAllAddAddress());
    dispatch(getLoggedUser());
    dispatch(getCartItems());
  }, []);

  // useEffect(() => {
  //   if (specificAddressInfo) {
  //     setShippingCity(specificAddressInfo.city);
  //     setPhone(specificAddressInfo.phone);
  //     setPostalCode(specificAddressInfo.postalCode);
  //     setDetails(specificAddressInfo.details);
  //     setUsername(specificAddressInfo.username);
  //     // setUserEmail(specificAddressInfo.email);
  //   }
  // }, [specificAddressInfo]);

  useEffect(() => {
    if (cartUserData) {
      if (cartUserData.status === "success") {
        if (cartUserData.data) {
          setCartId(cartUserData.data._id);
          setCartItems(cartUserData.data.products);
          setTotalAfterDiscount(cartUserData.data.totalAfterDiscount);
          setTotalCartPrice(cartUserData.data.totalCartPrice);
        }
      }
    }
  }, [cartUserData]);

  // useEffect(() => {
  //   if (userAddressData) {
  //     if (userAddressData.status === "success") {
  //       if (userAddressData.data) {
  //         setAllUserAddress(userAddressData.data);
  //       }
  //     }
  //   }
  // }, [userAddressData]);

  // useEffect(() => {
  //   if (specificAddressData) {
  //     if (specificAddressData.status === "success") {
  //       if (specificAddressData.data) {
  //         setSpecificAddressInfo(specificAddressData.data);
  //       }
  //     }
  //   }
  // }, [specificAddressData]);

  useEffect(() => {
    if (loggedUserData) {
      if (loggedUserData.status === 200) {
        if (loggedUserData.data) {
          if (loggedUserData.data.data.role !== "user") {
            navigate("/");
          }
        }
      } else if (loggedUserData.status === 401) {
        navigate("/");
      }
    }
  }, [loggedUserData]);

  
  return (
    <div className="checkout-page">
      <Helmet>
        <title>{`SHORTCUT | إكمال الطلب`}</title>
      </Helmet>
      <div className="container">
        <div className="checkout-page-wraper">
          <div className="cart-items">
            <div className="cart-items-wraper">
              {cartItems.length > 0
                ? cartItems.map((item, index) => (
                    <div className="cart-item" key={index}>
                      <div className="product-details">
                        <div className="price">
                          <p>{item.price}.00 L.E</p>
                        </div>
                        <div className="name-size">
                          <h4>{item.product.title}</h4>
                          <p>{item.color}</p>
                        </div>
                      </div>
                      <div className="product-img">
                        <span>{item.count}</span>
                        <img
                          src={`${serverUrl}/products/${item.product.imageCover}`}
                          alt="cart-item-img"
                        />
                      </div>
                    </div>
                  ))
                : null}
            </div>
            <div className="price-details">
              <span>
                <p>
                  {totalAfterDiscount
                    ? Math.ceil(totalAfterDiscount)
                    : Math.ceil(totalCartPrice)}
                  .00 L.E
                </p>
                <h5>:الإجمالي بعد الخصم</h5>
              </span>
              <span>
                <p>{shippingPrice} L.E</p>
                <h5>:مصاريف الشحن</h5>
              </span>
              <span className="total-after-discount">
                <p>
                  {totalAfterDiscount
                    ? Math.ceil(totalAfterDiscount + shippingPrice)
                    : Math.ceil(totalCartPrice + shippingPrice)}
                  .00 L.E
                </p>
                <h5>:الإجمالي</h5>
              </span>
            </div>
          </div>
          <div className="shipinning-info">
            <h2>معلومات الشحن</h2>
            {/* <div className="address">
              <label htmlFor="address">أختر عنوان للشحن</label>
              <select
                id="address"
                onChange={(e) => {
                  dispatch(getSpecificAddress(e.target.value));
                }}
              >
                <option id="" value={0}>
                  أختر عنوان
                </option>
                {allUserAddress.length > 0
                  ? allUserAddress.map((address, index) => (
                      <option key={index} value={address._id}>
                        {address.alias}
                      </option>
                    ))
                  : null}
              </select>
              <Link to="/add-address" className="add-address">
                إضافة عنوان جديد
              </Link>
            </div> */}
            <div className="address-details">
              <label htmlFor="address-details">العنوان بالتفصيل</label>
              <input
                type="text"
                value={details}
                id="address-details"
                placeholder="عنوان الشحن بالتفصيل"
                onChange={(e) => setDetails(e.target.value)}
              />
            </div>
            <div className="phone">
              <label htmlFor="phone">رقم هاتف للشحن</label>
              <input
                type="number"
                value={phone}
                id="phone"
                placeholder="رقم هاتف للشحن"
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            {/* <div className="email">
              <label htmlFor="email-address">البريد الإلكتروني</label>
              <input
                type="email"
                value={userEmail}
                id="email-address"
                placeholder="البريد الإلكتروني"
                onChange={(e) => setUserEmail(e.target.value)}
              />
            </div> */}
            <div className="city">
              <label htmlFor="city">محافظة الشحن</label>
              <input
                type="text"
                value={shippingCity}
                id="city"
                placeholder="محافظة الشحن"
                onChange={(e) => setShippingCity(e.target.value)}
              />
            </div>
            {/* <div className="name">
              <input
                type="number"
                value={postalCode}
                placeholder="الرقم البريدي"
                onChange={(e) => setPostalCode(e.target.value)}
              />
              <input
                type="text"
                value={username}
                placeholder="اسم العميل"
                onChange={(e) => setUsername(e.target.value)}
              />
            </div> */}
            <div className="complete-order">
              <Link to="" onClick={handelSendOrder}>
                {btnLoaderStatus ? <BtnLoader /> : "إكمال الطلب"}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
