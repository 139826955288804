import React from 'react'
import { Helmet } from 'react-helmet-async'

const TermsAndCondition = () => {
  return (
    <div className='static-page'>
      <Helmet>
        <title>{`SHORTCUT | الشروط والأحكام`}</title>
      </Helmet>
        <div className='static-page-container'>
            <h1>شروط وأحكام شورت كت</h1>
            <ul>
                <li>- معلومات عنا</li>
                <li>- كيف يُبرم العقد بينك وبيننا</li>
                <li>- حقك في إلغاء العقد</li>
                <li>- طريقة الدفع او السداد</li>
                <li>- الإرجاع والاستبدال</li>
                <li>- معلومات التوصيل</li>
                <li>يرجى قراءة هذه الشروط والأحكام بعناية قبل طلب أي منتجات من موقعنا على الإنترنت</li>
            </ul>
            <h5>- معلومات عنا</h5>
            <p>WWW.SHORTCUTMENSTORE.COM هو موقع تديره SHORTCUT نحن شركة مسجلة في مصر تحت رقم الشركة 4751 ومع مكتبنا الرئيسي في مصر.</p>
            <h5>- كيف يُبرم العقد بينك وبيننا</h5>
            <p>بعد تقديم الطلب ، ستتلقى رسالة منا للإقرار بأننا تلقينا طلبك.إذا أخطأت في طلبك ، يمكنك الاتصال بنا على 01111102271 أو إرسال بريد إلكتروني إلينا على: INFO@SHORTCUTMENSTORE.COM.</p>
            <p>عند تقديم طلب ، فإنك تتعهد بأن جميع التفاصيل التي تقدمها لنا لطلب سلع أو خدمات صحيحة ودقيقة. تقع على عاتقك مسؤولية إبلاغنا بأي تغييرات تطرأ على هذه التفاصيل في أسرع وقت ممكن.</p>
            <h5>- حقك في إلغاء العقد</h5>
            <p>من أجل إلغاء عقدك خلال فترة الإلغاء ، يرجى إخطارنا باستخدام جهات الاتصال الخاصة بناإذا كنت متعاقدًا كمستهلك في مصر وقمت بإلغاء طلبك خلال فترة الإلغاء ، فستتلقى استردادًا كاملاً للسعر المدفوع للمنتجات في غضون 14 يومًا ("فترة الاسترداد").تبدأ فترة استرداد الأموال بعد 5 أيام من استلامنا للمنتجات منك.</p>
            <p>
            <strong>يرجى ملاحظة: </strong>
            إذا تمت معالجة طلبك بالفعل ، فقد لا نتمكن من إيقاف إرسال العناصر إليك ، لذا ستظل بحاجة إلى إرجاع جميع المنتجات التي تم شراؤها في طلبك. يجب أن تهتم بشكل معقول بالمنتجات التي ترغب في إلغائها وعدم استخدامها أو ارتدائها.</p>
            <h5>- طريقة الدفع او السداد</h5>
            <p>نقدم طرق دفع مختلفة مثل بطاقة الائتمان (فيزا ، فيزا ديبيت ، ماستر كارد). كما نقبل الدفع نقدًا عند التسليم، أيضا الدفع عن طريق كارت بريميوم كارد وفاليو و جميع المحافظ الإلكترونية.</p>
            <h5>- المرتجع والاستبدال</h5>
            <p>إذا كنت بحاجة إلى إرجاع شيء ما لأن الملابس غير مناسبة ، أو ببساطة لم يعجبك ما لديك. تأكد من وجود جميع العلامات ، وأن العنصر مطابق تمامًا لاستلامه. تحتاج إلى إعادته إلينا في غضون 7 أيام من تاريخ التسليم.</p>
            <h5>يرجى اتباع العملية التالية لإعادة العنصر إلينا:</h5>
            <ul>
                <li>1 - أعد تغليف العنصر في عبوته الأصلية مع استمرار إرفاق الملصقات والعلامات.</li>
                <li>2 - إرفاق إيصال التسليم الأصلي بشكل آمن مع العنصر الخاص بك.</li>
            </ul>
            <h5>- معلومات التوصيل</h5>
            <p>خدمة التوصيل على الصعيد الوطني</p>
            <p>نقوم بالتوصيل لجميع أنحاء مصر (القاهرة ، الإسكندرية ، طنطا ، المحلة ، السويس ، الغردقة ، شرم الشيخ ، الأقصر ، أسوان ومدن أخرى)</p>
        </div>
    </div>
  )
}

export default TermsAndCondition