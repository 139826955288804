import {CREATE_CASH_ORDER, GET_ALL_ORDERS, GET_SPECIFIC_ORDER, PAYMENT_STATUS, DELIVER_STATUS, ONLINE_PAYMENT} from '../types'

const initState = {
    cashOrder: [],
    specificOrder: [],
    paymentStatus: [],
    deliverStatus: [],
    onlinepayment: []
}


const orderReducer = (state = initState, action)=>{
    switch(action.type)
    {
        case CREATE_CASH_ORDER:
            return {cashOrder: action.payload}
        case GET_ALL_ORDERS:
            return {orders: action.payload}  
        case GET_SPECIFIC_ORDER:
            return {specificOrder: action.payload}
        case PAYMENT_STATUS:
            return {paymentStatus: action.payload}
        case DELIVER_STATUS:
            return {deliverStatus: action.payload}
        case ONLINE_PAYMENT:
            return {onlinepayment: action.payload}              
        default:
            return state    
    }
}

export default orderReducer;