import React from "react";
import { Helmet } from "react-helmet-async";

const DeliveryAndReturns = () => {
  return (
    <div className="static-page">
      <Helmet>
        <title>{`SHORTCUT | التوصيل والمرتجع`}</title>
      </Helmet>
      <div className="static-page-container">
        <h1>الشحن والمرتجعات</h1>
        <u>كيفية عمل المرتجع أو الاستبدال:</u>
        <ul className="cyrcle">
            <li>يجب أن تكون القطع علي حالتها الأصلية بنفس الحالة المستلم بها.</li>
            <li>يتحمل العميل مصاريف الشحن في حالة عمل المرتجع عن طريق المندوب في حالة عدم وجود ديفوه.</li>
            <li>تتحمل شركة شورت كت مصاريف الشحن في حالة وجود ديفوه إذا كانت القطعة الوحيدة في الأوردر ولا تتحمل مصاريف الشحن في حالة وجود قطع اخرى سليمة، في حالة المرتجع وتتحمل مصاريف شحن إرسال القطعة مرة اخري.</li>
            <li>لا يتحمل العميل مصاريف الشحن في حالة عمل المرتجع عن طريق الفرع وبيتم استلام المبلغ اثناء التواجد في الفرع.</li>
            <li>المرتجع عن طريق المندوب بيتم إرسال المندوب وعند وصول القطعة إلينا بيتم فحصها والتأكد بانها علي حالتها الأصلية المرسلة بها وذلك خلال 5-7 ايام وبيتم التواصل لتحويل المبلغ ليس عن طريق المندوب.</li>
            <li>لعمل المرتجع في الحالتين بيتم الإبلاغ عن طريق الواتس آب وبيتم التأكيد مع العميل قبل إرسال المندوب او الذهاب الي الفرع.</li>
        </ul>
        <u>المرتجع عن طريق بريميوم وفاليو:</u>
        <ul className="cyrcle">
            <li>لا يمكن عمل المرتجع أو التبديل عن طريق الفروع بيتم فقط عن طريق المندوب.</li>
            <li>لا يوجد مرتجع جزئي وفي هذه الحالة بيتم عمل قسيمة شراء بقيمة المرتجع وإعادة الطلب عن طريق الأون لاين فقط.</li>
            <li>يتحمل العميل مصاريف الشحن في حالة عدم وجود ديفوه أو خطأ في المنتجات.</li>
            <li>الاسترجاع بيتم عن طريق إرسال المندوب وبعد وصول الأصناف إلينا بيتم فحصها والتأكد انها علي نفس حالتها الأصلية وبعد ذلك يتم استرجاع قيمة الاوردر للعميل.</li>
            <li>بيتم عمل المرتجع لمبلغ الأوردر عن طريق شركة فاليو/ بريميوم خلال 14 يوم من تاريخ وصول المرتجع إلينا.</li>
            <li>تتحمل شركة شورت كت مصاريف الشحن في حالة ديفوه أو خطأ في المنتجات وبعد وصول القطعة إلينا بيتم إرسال قسيمة الشراء لعمل أوردر جديد أو إعادة إرسال نفس القطعة في حالة توافرها.</li>
        </ul>
        <u>وفقًا لسياسة الاسترجاع والاستبدال الخاصة بنا، لا يمكن إرجاع أو استرداد المنتجات في الحالات التالية لضمان السلامة لعملاء شورت كت :</u>
        <ul className="cyrcle">
            <li>المنتجات التي تعرضت للكي أو الغسيل، وأيضاً المنتجات التي تعرضت للتلف ولم تكن بحالتها الأصلية والأحذية بدون عبوتها الأصلية.</li>
            <li>المنتجات التي تم إزالة بطاقة السعر أو العلامة التجارية منها.</li>
            <li>الملابس الداخلية، ملابس البحر، الكاب، الاسكارف، الساعات، الإكسسوار، المحافظ ، البارفانات والشرابات.</li>
            <li>لن يتم قبول المرتجعات التي تخطت الـ 14 يومًا من تاريخ التسليم.</li>
        </ul>
        <u>الاسترجاع اثناء العروض يتم عمل مرتجع للطلب بأكمله واسترداد المبلغ المدفوع بدون مصاريف الشحن وفي حالة الرغبة في طلب شراء جديد يتم الشراء بالعروض الحالية.</u>
      </div>
    </div>
  );
};

export default DeliveryAndReturns;
