import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getResetCode } from '../../redux/actions/authAction'

const ResetCode = () => {
  const [resetCode, setResetCode] = useState("")

  const [errorMsg, setErrorMsg] = useState("")
  const [hideMsg, setHideMsg] = useState(true)


  const resetCodeData = useSelector(state=>state.authReducer.resetCode)
  const dispatch = useDispatch()

  const handelSubmit = async(e)=>{
    e.preventDefault()
    await dispatch(getResetCode(
        {
            resetCode,
        }
    ))
  }

  useEffect(()=>{
    if(resetCodeData){
      console.log(resetCodeData)
      if(resetCodeData.status === 400){
        if(resetCodeData.data){
          setErrorMsg(resetCodeData.data.message)
          setTimeout(()=>{
            setHideMsg(false)
          },3000)
        }
      }else if(resetCodeData.status === 200){
        if(resetCodeData.data){
          if(resetCodeData.data.token)
          localStorage.setItem("token", resetCodeData.data.token)
          window.location.href='/'
        }
      }
    }
  },[resetCodeData])


  return (
    <div className='login-page'>
        <div className='login-page-wraper'>
            <h2>إدخال الرمز المؤلف من ٦ أرقام</h2>
            <p className='text-forgot'>تحقق من بريدك الإلكتروني للحصول على رمز التحقق</p>
            <div className="msg-box">
          {
            errorMsg ? (
              <div className="err" style={{display: hideMsg ? "flex":"none"}}>
                <small>Error: </small>
                <p>{errorMsg}</p>
              </div>
            ):null
          }
        </div>
            <form>
                <label htmlFor="email-login"><span>*</span>رمز التحقق</label>
                <input type='text' id='email-login' value={resetCode} placeholder='رمز التحقق المكون من ٦ أرقام'
                onChange={(e)=> setResetCode(e.target.value)}/>
                <input type='submit' className='submit' value="إرسال" onClick={(e)=> handelSubmit(e)}/>
            </form>
        </div>
    </div>
  )
}

export default ResetCode