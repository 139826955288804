import React from 'react'
import { Link } from "react-router-dom";

const Sidebar = () => {
  return (
    <aside className="sidebar">
    <h2>لوحة تحكم شورت كت</h2>
    <ul>
      <li>
        <Link to="/admin">إدارة المنتجات</Link>
      </li>
      <li>
        <Link to="/admin/manage-orders">إدارة الطلبات</Link>
      </li>
      <li>
        <Link to="/admin/manage-coupons">إدارة الكوبونات</Link>
      </li>
      <li>
        <Link to="/admin/manage-categories">إدارة التصنيفات</Link>
      </li>
      <li>
        <Link to="/admin/manage-users">إدارة المٌستخدمين</Link>
      </li>
      <li>
        <Link to="/admin/add-category">إضافة تصنيف</Link>
      </li>
      <li>
        <Link to="/admin/add-brand">إضافة ماركة</Link>
      </li>
      <li>
        <Link to="/admin/add-product">إضافة مُنتج</Link>
      </li>
      <li>
        <Link to="/admin/add-coupon">إضافة كوبون</Link>
      </li>
    </ul>
  </aside>
  )
}

export default Sidebar