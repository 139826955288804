import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Pay from "../../images/svg/pay.svg";
import Exchange from "../../images/svg/exchange.svg";
import Life from "../../images/svg/life-preserver.svg";
import Delivery from "../../images/svg/delivery.svg";


const Services = () => {
  return (
    <section>
      <div className="services-wraper">
        <Swiper
          className="mySwiper"
          slidesPerView={4}
          spaceBetween={20}
          breakpoints={{
            350: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
        >
          <SwiperSlide>
            <div className="service">
              <div className="details">
                <h5>استبدال واسترجاع خلال 14 يوم</h5>
                <p>استبدل او استرجع المنتج بسهوله فى خلال 14 يوم</p>
              </div>
              <img className="img" src={Exchange} alt="exchange" loading="lazy" />
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="service">
              <div className="details">
                <h5>خدمة عملاء احترافية فى خدمتك</h5>
                <p>من السبت الى الخميس من الساعة 10 صباحا حتى 6 مساء</p>
              </div>
              <img className="img" src={Life} alt="exchange" loading="lazy" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="service">
              <div className="details">
                <h5>ادفع كاش أو فيزا وانت مطمن</h5>
                <p>
                  نوفر لك وسائل دفع آمنة, اختار من طرق الدفع المتاحة ( فيزا -
                  بطاقات ائتمانية - كاش .. )
                </p>
              </div>
              <img className="img" src={Pay} alt="delevired" loading="lazy" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="service">
              <div className="details">
                <h5>توصيل سريع</h5>
                <p>توصيل خلال 3 ايام عمل فى القاهرة والجيزه</p>
              </div>
              <img className="img" src={Delivery} alt="exchange" loading="lazy" />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default Services;
