import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { forgotPassword } from '../../redux/actions/authAction'

const ForgotPassword = () => {
  const [email, setEmail] = useState("")

  const [errorsList, setErrorsList] = useState([])
  const [errorMsg, setErrorMsg] = useState("")
  const [hideMsg, setHideMsg] = useState(true)


  const forgotPasswordData = useSelector(state=>state.authReducer.forgotPassword)
  const dispatch = useDispatch()

  const handelSubmit = async(e)=>{
    e.preventDefault()
    await dispatch(forgotPassword({
      email,
    }))
  }

  useEffect(()=>{
    if(forgotPasswordData){
      if(forgotPasswordData.status === 404){
        if(forgotPasswordData.data){
          setErrorMsg(forgotPasswordData.data.message)
        }
      }else if(forgotPasswordData.status === 500){
        if(forgotPasswordData.data){
          setErrorMsg(forgotPasswordData.data.message)
        }
      }else if(forgotPasswordData.status === 200){
        if(forgotPasswordData.data){
          if(forgotPasswordData.data.token)
          localStorage.setItem("token", forgotPasswordData.data.token)
          window.location.href='/'
        }
      }
    }
  },[forgotPasswordData])


  return (
    <div className='login-page'>
        <div className='login-page-wraper'>
            <h2>هل نسيت كلمة المرور؟</h2>
            <p className='text-forgot'>سنرسل رمز تحقق إلى هذا البريد الإلكتروني إذا كان متطابق مع حساب مُسجل لدينا</p>
            <div className="msg-box">
          {
            errorMsg ? (
              <div className="err" style={{display: hideMsg ? "flex":"none"}}>
                <small>Error: </small>
                <p>{errorMsg}</p>
              </div>
            ):null
          }
        </div>
            <form>
                <label htmlFor="email-login"><span>*</span>البريد الإلكتروني</label>
                <input type='email' id='email-login' value={email} placeholder='أدخل بريدك الإلكتروني'
                onChange={(e)=> setEmail(e.target.value)}/>
                <input type='submit' className='submit' value="التالي" onClick={(e)=> handelSubmit(e)}/>
            </form>
        </div>
    </div>
  )
}

export default ForgotPassword