import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getSpecificOrder } from "../../redux/actions/orderAction";
import Spiner from "../../utilities/Spiner";
import serverUrl from '../../api/serverUrl'
import { useNavigate, Link } from "react-router-dom";
import { getLoggedUser } from "../../redux/actions/authAction";
import { shippingPrice } from "../../api/ShippingPrice";
import { Helmet } from "react-helmet-async";

const OrderDetails = () => {
  const [specificOrderDetails, setSpecificOrderDetails] = useState([]);
  const [orderDetailsLoading, setOrderDetailsLoading] = useState([]);

  const { id } = useParams();

  const dispatch = useDispatch();
  const specificOrderData = useSelector(
    (state) => state.orderReducer.specificOrder
  );
  const loggedUserData = useSelector(state=>state.authReducer.getMe)

  const navigate = useNavigate()

  useEffect(() => {
    if (id) {
      dispatch(getSpecificOrder(id));
    }
  }, [id]);

  useEffect(() => {
    if (specificOrderData) {
      console.log(specificOrderData)
      if (specificOrderData.status === 200) {
        if (specificOrderData.data) {
          setSpecificOrderDetails(specificOrderData.data.data);
          setOrderDetailsLoading(false);
        }
      }
    }
  }, [specificOrderData]);

  useEffect(()=>{
    dispatch(getLoggedUser())
  },[])

  useEffect(()=>{
    if(loggedUserData){
      if(loggedUserData.status === 200){
        if(loggedUserData.data){
          if(loggedUserData.data.data.role !== "admin"){
            navigate('/')
          }
        }
      }else if(loggedUserData.status === 401){
        navigate('/')
      }
    }
  },[loggedUserData])

  return (
    <div className="order-details-page">
      <Helmet>
        <title>{`SHORTCUT | تفاصيل الطلب`}</title>
      </Helmet>
      <div className="container">
        {orderDetailsLoading ? (
          <Spiner />
        ) : specificOrderDetails ? (
          <div className="order-wraper">
            <h2>تفاصيل الطلب رقم #{specificOrderDetails.id}</h2>
            <div className="order-details-container">
              <div className="order-items">
                <div className="order-products-wraper">
                  {specificOrderDetails.cartItems
                    ? specificOrderDetails.cartItems.map((product, index) => (
                        <div className="product-cart" key={index}>
                          <div className="prodeuct-details">
                            <ul>
                              <li>
                                <p>
                                  {product.product && product.product.title}
                                </p>
                                :كود المنتج
                              </li>
                              <li>
                                <p className="size">{product.color}</p>
                                :المقاس
                              </li>
                              <li>
                                <p className="size">{product.count}</p>
                                :عدد القطع
                              </li>
                              <li>
                                <p className="size">{product.price}.00 L.E</p>
                                :سعر القطعة الواحدة
                              </li>
                            </ul>
                          </div>
                          <div className="product-img">
                            <Link to={`/product/${product.product._id}`} target="_blank">
                            <img
                              src={`${serverUrl}/products/${product.product.imageCover}`}
                              alt="product-img"
                            />
                            </Link>
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              </div>
              <div className="order-details">
                <div className="user-info">
                  <h3>بيانات العميل</h3>
                  <ul>
                    <li>
                      <p>
                        {specificOrderDetails.user &&
                          specificOrderDetails.user.name}
                      </p>
                      :اسم العميل
                    </li>
                  </ul>
                </div>
                <div className="shipping-info">
                  <h3>بيانات الشحن</h3>
                  <ul>
                    <li>
                      <p>
                        {specificOrderDetails.shippingAddress &&
                          specificOrderDetails.shippingAddress.city}
                      </p>
                      :المحافظة
                    </li>
                    <li>
                      <p>
                        {specificOrderDetails.shippingAddress &&
                          specificOrderDetails.shippingAddress.details}
                      </p>
                      :العنوان بالتفصيل
                    </li>
                    <li>
                      <p>
                        {specificOrderDetails.shippingAddress &&
                          specificOrderDetails.shippingAddress.phone}
                      </p>
                      :رقم الهاتف
                    </li>
                    <li>
                      <p>
                        {specificOrderDetails.shippingAddress &&
                          specificOrderDetails.shippingAddress.postalCode}
                      </p>
                      :الرقم البريدي
                    </li>
                    <li>
                      <p>
                        {specificOrderDetails.paymentMethodType &&
                          specificOrderDetails.paymentMethodType}
                      </p>
                      :طريقة الدفع
                    </li>
                    <li>
                      <p>
                        {specificOrderDetails.trnxId &&
                          specificOrderDetails.trnxId}
                      </p>
                      :رقم العملية
                    </li>
                    <li>
                      <p>
                        {specificOrderDetails.orderPaymobId &&
                          specificOrderDetails.orderPaymobId}
                      </p>
                      :رقم الطلب
                    </li>
                    <li>
                      <p>
                        {Math.ceil(specificOrderDetails.totalOrderPrice) + shippingPrice}.00 L.E
                      </p>
                      :إجمالي الفاتورة
                    </li>
                    <li>
                      <p>{specificOrderDetails.createdAt}</p>
                      :تاريخ الطلب
                    </li>
                  </ul>
                </div>
                <div className="order-status">
                  <h3>حالة الطلب</h3>
                  <ul>
                    <li>
                      <p>
                        {specificOrderDetails.isDelivered
                          ? "تم الشحن"
                          : "لم يتم الشحن"}
                      </p>
                      :حالة الشحن
                    </li>
                    <li>
                      <p>
                        {specificOrderDetails.isPaid
                          ? "تم الدفع"
                          : "لم يتم الدفع"}
                      </p>
                      :حالة الدفع
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default OrderDetails;
