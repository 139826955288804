export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY"
export const CREATE_CATEGORY = "CREATE_CATEGORY"
export const SINGLE_CATEGORY = "SINGLE_CATEGORY"
export const DELETE_CATEGORY = "DELETE_CATEGORY"
export const EDIT_CATEGORY = "EDIT_CATEGORY"


export const CREATE_BRAND = "CREATE_BRAND"
export const GET_ALL_BRAND = "GET_ALL_BRAND"

export const LOGIN_USER = "LOGIN_USER"
export const CREATE_USER = "CREATE_USER"
export const GET_ME = "GET_ME"
export const LOGME_OUT = "LOGME_OUT"
export const FORGOT_PASSWORD = "FORGOT_PASSWORD"
export const RESET_CODE = "RESET_CODE"
export const RESET_PASSWORD = "RESET_PASSWORD"

export const GET_ALL_PRODUCT_REVIEWS = "GET_ALL_PRODUCT_REVIEWS"
export const CREATE_REVIEW = "CREATE_REVIEW"


export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST"
export const GET_ALL_WISHLIST = "GET_ALL_WISHLIST"
export const DELETE_FROM_WISHLIST = "DELETE_FROM_WISHLIST"

export const CREATE_PRODUCT = "CREATE_PRODUCT"
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS"
export const GET_ALL_PRODUCTS_FILTER = "GET_ALL_PRODUCTS_FILTER"
export const SINGLE_PRODUCT = "SINGLE_PRODUCT"
export const EDIT_PRODUCT = "EDIT_PRODUCT"
export const SEARCH = "SEARCH"

export const ADD_TO_CART = "ADD_TO_CART"
export const GET_CART_ITEMS = "GET_CART_ITEMS"
export const UPDATE_QTY = "UPDATE_QTY"

export const CREATE_COUPON = "CREATE_COUPON"
export const APPLY_COUPON = "APPLY_COUPON"
export const GET_ALL_COUPONS = "GET_ALL_COUPONS"
export const DELETE_CART_ITEMS = "DELETE_CART_ITEMS"
export const DELETE_COUPON = "DELETE_COUPON"

export const ADD_ADDRESS = "ADD_ADDRESS"
export const GET_ALL_ADDRESS = "GET_ALL_ADDRESS"
export const DELETE_ADDRESS = "DELETE_ADDRESS"
export const GET_SPECIFIC_ADDRESS = "GET_SPECIFIC_ADDRESS"

export const CREATE_CASH_ORDER = "CREATE_CASH_ORDER"
export const GET_ALL_ORDERS = "GET_ALL_ORDERS"
export const GET_SPECIFIC_ORDER = "GET_SPECIFIC_ORDER"
export const PAYMENT_STATUS = "PAYMENT_STATUS"
export const DELIVER_STATUS = "DELIVER_STATUS"
export const ONLINE_PAYMENT = "ONLINE_PAYMENT"

export const GET_ALL_USERS = "GET_ALL_USERS"
export const GET_SPECIFIC_USER = "GET_SPECIFIC_USER"
export const DELETE_USER_ADMIN = "DELETE_USER_ADMIN"