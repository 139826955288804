import React from 'react'
import Product from '../products/Product'
import Spiner from '../../utilities/Spiner'

const ProductHomePage = ({title, products}) => {
          
  return (
    <div className='products-homePage'>
        <div className='products-homePage-container'>
            <h2 className='title'>{title}</h2>
            <div className='products-wraper'>
            {
            products.length > 0 ? (
              products.map((product, index)=>(
                <Product key={index} product={product}/>
              ))
            ):<Spiner/>
          }
            </div>
        </div>
    </div>
  )
}

export default ProductHomePage