import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../utilities/Sidebar";
import Trash from "../../images/svg/trash-white.svg";
import UserImg from "../../images/svg/user.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getLoggedUser } from "../../redux/actions/authAction";
import { getAllUsers, deleteUserAdmin } from "../../redux/actions/usersAction";
import Pagination from "../../utilities/Pagination";
import { Helmet } from "react-helmet-async";

const ManageUsers = () => {
  const [usersList, setUsersList] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [pageNumber, setPageNumber] = useState("");
  const [pageResult, setPageResult] = useState("");

  const dispatch = useDispatch();
  const usersData = useSelector((state) => state.usersReducer.allUsers);
  const loggedUserData = useSelector((state) => state.authReducer.getMe);

  const navigate = useNavigate();

  //delete coupon
  const handelDeleteUser = (id) => {
    dispatch(deleteUserAdmin(id));
    window.location.reload();
  };

  useEffect(() => {
    dispatch(getLoggedUser());
    dispatch(getAllUsers());
  }, []);

  useEffect(() => {
    if (usersData) {
      if (usersData.status === 200) {
        if (usersData.data) {
          setUsersList(usersData.data.data);
          setPageResult(usersData.data.paginationResult)
          setAllUsers(usersData.data.data);
        }
      }
    }
  }, [usersData]);

  useEffect(()=>{
    if(loggedUserData){
      if(loggedUserData.status === 200){
        if(loggedUserData.data){
          if(loggedUserData.data.data.role !== "admin"){
            navigate('/')
          }
        }
      }else if(loggedUserData.status === 401){
        navigate('/')
      }
    }
  },[loggedUserData])

  const onSearchUser = (e)=>{
    if(e.target.value !== ""){
      const filtredUserList = usersList.filter((user)=> user.phone.includes(e.target.value))
      setUsersList(filtredUserList)
    }else{
      setUsersList(allUsers)
    }
  }

  useEffect(()=>{
    dispatch(getAllUsers(`limit=${20}&page=${pageNumber}`));
  }, [pageNumber])

  //to get page number
  const getPage = (pageNumber)=>{
    setPageNumber(pageNumber)
  }

  return (
    <div className="admin-page">
      <Helmet>
        <title>{`SHORTCUT | إدارة المُستخدمين`}</title>
      </Helmet>
      <div className="container">
        <div className="admin-page-wraper">
          <Sidebar />
          <section className="dashboard-container">
            <h3>إدارة المٌستخدمين</h3>
            <div className="search-form">
              <input type="text" placeholder="أدخل رقم الهاتف للمٌستخدم" onChange={(e)=>onSearchUser(e)}/>
            </div>
            <div className="add-wraper">
              {usersList.length > 0 ? (
                usersList.map((user, index) => (
                  <div className="coupon-card" key={index}  style={{backgroundImage: user.role === "admin" ? `repeating-linear-gradient(to right, #a2682a, #be8c3c, #be8c3c)`:""}}>
                    <img
                      className="trash"
                      src={Trash}
                      alt="delete-coupon"
                      onClick={() => handelDeleteUser(user._id)}
                    />
                    <ul className="coupon-details">
                      <li>
                        <p>{user.name}</p>
                        :اسم المٌستخدم
                      </li>
                      <li>
                        <p>{user.phone}</p>
                        :رقم الهاتف
                      </li>
                      <li>
                        <p>{user.role}</p>
                        :الصلاحية
                      </li>
                    </ul>
                    <Link className="user-page" to={`/user-details/${user._id}`}>
                    <div className="coupon-img">
                    <img src={UserImg} alt="user"/>
                    </div>
                    </Link>
                  </div>
                ))
              ) : (
                <p className="no-items">
                  لا يوجد مستخدمين حتى الأن
                  <Link to="/admin/add-coupon">إضافة مٌستخدم</Link>
                </p>
              )}
            </div>
            <div className="pagnation-container">
              <Pagination pageResult={pageResult} onPress={getPage}/>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ManageUsers