import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../utilities/Sidebar";
import ImageUploading from "react-images-uploading";
import Upload from "../../images/svg/upload-img.svg";
import { getAllCategory } from "../../redux/actions/categoryAction";
import {
  editProduct,
  getSingleProduct,
} from "../../redux/actions/productAction";
import { useParams, useNavigate } from "react-router-dom";
import { getLoggedUser } from "../../redux/actions/authAction";
import BtnLoader from "../../utilities/BtnLoader";
import { Helmet } from "react-helmet-async";

const EditProduct = () => {
  const [title, setTitle] = useState("");
  const [barcode, setBarcode] = useState("");
  const [des, setDes] = useState("");
  const [qty, setQty] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [priceAfterDiscount, setPriceAfterDiscount] = useState("");
  const [priceBeforeDiscount, setPriceBeforeDiscount] = useState("");
  const [images, setImages] = useState([]);
  const maxNumber = 5;
  const [categories, setCategories] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [age, setAge] = useState("");
  const [season, setSeason] = useState("");
  const [productId, setProductId] = useState("");
  const [btnLoaderStatus, setBtnLoaderStatus] = useState(false)


  const { id } = useParams();
  useEffect(() => {
    if(id){
      setProductId(id);
    }
  }, [id]);

  const onChangeSize = (e) => {
    if (!sizes.includes(e.target.value) && e.target.value !== "") {
      setSizes([...sizes, e.target.value]);
    }
  };

  const allCategories = useSelector(
    (state) => state.categoryReducer.categories
  );
  const loggedUserData = useSelector((state) => state.authReducer.getMe);

  const navigate = useNavigate();

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  // Product images array
  let imgArray = [];
  images.map((img) => imgArray.push(img.file));

  const dispatch = useDispatch();
  const productData = useSelector(
    (state) => state.productReducer.singleProduct
  );

  const editProductData = useSelector(
    (state) => state.productReducer.editProduct
  );

  useEffect(() => {
    if (editProductData) {
      setBtnLoaderStatus(false)
      if (editProductData.status === 200) {
        window.location.reload();
      }
    }
  }, [editProductData]);

  useEffect(() => {
    if (productData) {
      if (productData.data) {
        setTitle(productData.data.title);
        setDes(productData.data.description);
        setPriceAfterDiscount(productData.data.priceAfterDiscount);
        setPriceBeforeDiscount(productData.data.price);
        setQty(productData.data.quantity);
        setBarcode(productData.data.barcode);
        setCategoryId(productData.data.category);
      }
    }
  }, [productData]);

  const handelSubmit = async (e) => {
    e.preventDefault();
    setBtnLoaderStatus(true)
    const formData = new FormData();
    formData.append("title", title);
    formData.append("barcode", barcode);
    formData.append("description", des);
    formData.append("category", categoryId);
    formData.append("imageCover", imgArray[0]);
    imgArray.map((img) => {
      formData.append("images", img);
    });
    formData.append("price", priceBeforeDiscount);
    formData.append("priceAfterDiscount", priceAfterDiscount);
    formData.append("quantity", qty);
    formData.append("age", age);
    formData.append("season", season);
    sizes.map((size) => {
      formData.append("availableSizes", size);
    });
    dispatch(editProduct(formData, productId));
  };

  useEffect(() => {
    dispatch(getLoggedUser());
    dispatch(getAllCategory());
    dispatch(getSingleProduct(productId));
  }, []);

  useEffect(() => {
    if (productId) {
      dispatch(getSingleProduct(productId));
    }
  }, [productId]);

  useEffect(() => {
    if (allCategories) {
      if (allCategories.status === 200) {
        if (allCategories.data) {
          setCategories(allCategories.data.data);
        }
      }
    }
  }, [allCategories]);

  useEffect(() => {
    if (loggedUserData) {
      if (loggedUserData.status === 200) {
        if (loggedUserData.data) {
          if (loggedUserData.data.data.role !== "admin") {
            navigate("/");
          }
        }
      } else if (loggedUserData.status === 401) {
        navigate("/");
      }
    }
  }, [loggedUserData]);

  return (
    <div className="admin-page">
       <Helmet>
        <title>{`SHORTCUT | تعديل مُنتج`}</title>
      </Helmet>
      <div className="container">
        <div className="admin-page-wraper">
          <Sidebar />
          <section className="dashboard-container">
            <h3>إضافة مُنتج جديد</h3>
            <div className="add-wraper">
              <div className="upload-wraper product-upload">
                <ImageUploading
                  multiple
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                      {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                      <button
                        style={isDragging ? { color: "red" } : undefined}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        <img src={Upload} alt="product-images" />
                      </button>
                      &nbsp;
                      {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          <img src={image["data_url"]} alt="" width="100" />
                          <div className="image-item__btn-wrapper">
                            <button onClick={() => onImageUpdate(index)}>
                              Update
                            </button>
                            <button onClick={() => onImageRemove(index)}>
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>
              </div>
              <form>
                <input
                  type="text"
                  placeholder="اسم التصنيف"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="كود الصنف"
                  value={barcode}
                  onChange={(e) => setBarcode(e.target.value)}
                />
                <textarea
                  maxLength={200}
                  style={{ resize: "none" }}
                  placeholder="وصف المُنتج"
                  value={des}
                  onChange={(e) => setDes(e.target.value)}
                />
                <input
                  type="number"
                  placeholder="الكمية"
                  value={qty}
                  onChange={(e) => setQty(e.target.value)}
                />
                <input
                  type="number"
                  placeholder="السعر قبل الخصم"
                  value={priceBeforeDiscount}
                  onChange={(e) => setPriceBeforeDiscount(e.target.value)}
                />
                <input
                  type="number"
                  placeholder="السعر بعد الخصم"
                  value={priceAfterDiscount}
                  onChange={(e) => setPriceAfterDiscount(e.target.value)}
                />
                <select onChange={(e) => setCategoryId(e.target.value)}>
                  <option>أختر تصنيف</option>
                  {categories
                    ? categories.map((cat, index) => (
                        <option value={cat._id} key={index}>
                          {cat.name}
                        </option>
                      ))
                    : null}
                </select>
                <select onClick={(e) => setAge(e.target.value)}>
                  <option value="أختر العمر">أختر العمر</option>
                  <option value="رجال">رجال</option>
                  <option value="أطفال">أطفال</option>
                </select>
                <select onClick={(e) => setSeason(e.target.value)}>
                  <option value="أختر الموسم">أختر الموسم</option>
                  <option value="صيف">صيف</option>
                  <option value="شتاء">شتاء</option>
                  <option value="جميعاً">جميعاً</option>
                </select>
                <select multiple onClick={(e) => onChangeSize(e)}>
                <option value="xs">XS</option>
                  <option value="s">S</option>
                  <option value="m">M</option>
                  <option value="l">L</option>
                  <option value="xl">XL</option>
                  <option value="2x">2X</option>
                  <option value="3x">3X</option>
                  <option value="4x">4X</option>
                  <option value="5x">5X</option>
                  <option value="6x">6X</option>
                  <option value="4">4</option>
                  <option value="6">6</option>
                  <option value="8">8</option>
                  <option value="10">10</option>
                  <option value="12">12</option>
                  <option value="14">14</option>
                  <option value="30">30</option>
                  <option value="32">32</option>
                  <option value="34">34</option>
                  <option value="36">36</option>
                  <option value="38">38</option>
                  <option value="40">40</option>
                  <option value="42">42</option>
                  <option value="44">44</option>
                  <option value="46">46</option>
                  <option value="48">48</option>
                  <option value="50">50</option>
                  <option value="52">52</option>
                  <option value="54">54</option>
                  <option value="56">56</option>
                  <option value="41">41</option>
                  <option value="42">42</option>
                  <option value="43">43</option>
                  <option value="44">44</option>
                  <option value="45">45</option>
                </select>
                <button
                  className="submit"
                  type="submit"
                  onClick={(e) => handelSubmit(e)}
                >
                {
                  btnLoaderStatus ? (
                    <BtnLoader/>
                  ):(
                    "تعديل المُنتج"
                  )
                }
                </button>
              </form>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default EditProduct;
