import React from "react";
import { Helmet } from "react-helmet-async";

const Aboutus = () => {
  return (
    <div className="static-page">
      <Helmet>
        <title>{`SHORTCUT | من نحن`}</title>
      </Helmet>
      <div className="static-page-container">
        <h1>عن شورت كت</h1>
        <p>
        تأسست شورت كت في عام 2016. شورت كت هي واحدة من أشهر ماركات الأزياء الرجالية في مصر المتخصصة في الملابس الكاجوال وتنمو وتنتشر باستمرار على الصعيد الوطني في جميع أنحاء مصر وتقع في أكثر المواقع المركزية الراقية ومراكز التسوق وشوارع البيع بالتجزئة.
        </p>
      </div>
    </div>
  );
};

export default Aboutus;
