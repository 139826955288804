import {CREATE_PRODUCT, GET_ALL_PRODUCTS, GET_ALL_PRODUCTS_FILTER, SINGLE_PRODUCT, EDIT_PRODUCT, SEARCH} from '../types'


const initState = {
    creatProduct: [],
    products: [],
    productsFilter: [],
    singleProduct: {},
    editProduct: [],
    search: [],
}

const productReducer = (state = initState, action)=>{
    switch(action.type)
    {
        case CREATE_PRODUCT:
            return {creatProduct: action.payload}
        case GET_ALL_PRODUCTS:
            return {products: action.payload}
        case GET_ALL_PRODUCTS_FILTER:
            return {productsFilter: action.payload}    
        case SINGLE_PRODUCT:
            return {singleProduct: action.payload}
        case EDIT_PRODUCT:
            return {editProduct: action.payload}
        case SEARCH:
            return {search: action.payload}             
        default:
            return state    
    }
}

export default productReducer