import baseURL from '../../api/baseURL'
import {CREATE_PRODUCT, GET_ALL_PRODUCTS, GET_ALL_PRODUCTS_FILTER, SINGLE_PRODUCT, EDIT_PRODUCT, SEARCH} from '../types'

export const createProduct = (formData)=> async(dispatch)=>{
    try{
        const config = {
            headers:{"Content-Type": "multipart/form-data", Authorization: `Bearer ${localStorage.getItem("token")}`}
        }
        const res = await baseURL.post('/api/v1/products', formData, config)
        dispatch({type: CREATE_PRODUCT, payload: res})
    }catch(e){
        dispatch({type: CREATE_PRODUCT, payload: e.response})
    }
}


export const editProduct = (formData, id)=> async(dispatch)=>{
    try{
        const config = {
            headers:{"Content-Type": "multipart/form-data", Authorization: `Bearer ${localStorage.getItem("token")}`}
        }
        const res = await baseURL.put(`/api/v1/products/${id}`, formData, config)
        dispatch({type: EDIT_PRODUCT, payload: res})
    }catch(e){
        dispatch({type: EDIT_PRODUCT, payload: e.response})
    }
}


export const getAllProducts = (str)=> async(dispatch)=>{
    try{
        const res = await baseURL.get(`/api/v1/products?${str}`)
        dispatch({type: GET_ALL_PRODUCTS, payload: res})
    }catch(e){
        dispatch({type: GET_ALL_PRODUCTS, payload: e.response})
    }
}

export const getAllProductsWithFilter = (str)=> async(dispatch)=>{
    try{
        const res = await baseURL.get(`/api/v1/products?${str}`)
        dispatch({type: GET_ALL_PRODUCTS_FILTER, payload: res})
    }catch(e){
        dispatch({type: GET_ALL_PRODUCTS_FILTER, payload: e.response})
    }
}

export const getAllProductsSearch = (str)=> async(dispatch)=>{
    try{
        const res = await baseURL.get(`/api/v1/products?${str}`)
        dispatch({type: SEARCH, payload: res})
    }catch(e){
        dispatch({type: SEARCH, payload: e.response})
    }
}

export const getSingleProduct = (id)=> async(dispatch)=>{
    try{
        const res = await baseURL.get(`/api/v1/products/${id}`)
        dispatch({type: SINGLE_PRODUCT, payload: res.data})
    }catch(e){
        dispatch({type: SINGLE_PRODUCT, payload: e.data})
    }
}