import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import Slider from "../components/products/ImagesSlider";
import Heart from "../images/svg/heart.svg";
import HeartRed from "../images/svg/heart-red.svg";
import User from "../images/svg/user.svg";
import LookLike from "../components/products/LookLike";
import {
  addToWishlist,
  getLoggedUserWishlist,
  removeProductFromWishlist,
} from "../redux/actions/wishlistAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleProduct } from "../redux/actions/productAction";
import { addToCart } from "../redux/actions/cartAction";
import ReactStars from "react-rating-stars-component";
import { createProductReview } from "../redux/actions/productReviewsAction";
import Spiner from "../utilities/Spiner";
import { toast } from "react-toastify";
import { getLoggedUser } from "../redux/actions/authAction";
import BtnLoader from "../utilities/BtnLoader";

const ProductPage = () => {
  const [heart, setHeart] = useState(false);
  const [productId, setProductId] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [sizeIndex, setSizeIndex] = useState("");
  const [singleProduct, setSingleProduct] = useState({});
  const [productCategory, setProductCategory] = useState("");
  const [productAge, setProductAge] = useState("");
  const [loggedUser, setLoggedUser] = useState("");
  const [btnLoaderStatus, setBtnLoaderStatus] = useState(false);

  const [productLoading, setProductLoading] = useState(true);

  const [boxId, setBoxId] = useState("1");
  const [toggleShowReviewBox, setToggleShowReviewBox] = useState(false);
  const [review, setReview] = useState("");
  const [rating, setRating] = useState("");
  const [allProductReviews, setAllProductReviws] = useState([]);

  const notifyError = (msg) => toast.error(msg);
  const notifySuccess = (msg) => toast.success(msg);


  const dispatch = useDispatch();

  const writeReviewData = useSelector(
    (state) => state.productReviewsReducer.review
  );

  const getLogedUserData = useSelector((state) => state.authReducer.getMe);

  const allWishlist = useSelector(
    (state) => state.wishlistReducer.loggedUserWishlist
  );

  const productData = useSelector(
    (state) => state.productReducer.singleProduct
  );

  const ratingChanged = (newRating) => {
    setRating(newRating);
  };

  const handelWriteReview = () => {
    dispatch(
      createProductReview(productId, {
        review,
        rating,
      })
    );
  };

  useEffect(() => {
    if (writeReviewData) {
      if (writeReviewData.status === 201) {
        notifySuccess("تم إضافة تقييم المٌنتج بنجاح");
      } else if (writeReviewData.status === 400) {
        notifyError("لقد قمت بتقيم هذا المٌنتج بالفعل");
      } else if (writeReviewData.status === 401) {
        notifyError("من فضلك قم بتسجيل الدخول أولاً");
      }
    }
  }, [writeReviewData]);

  const { id } = useParams();
  useEffect(() => {
    setProductId(id);
  }, [id]);

  //get single product ID
  useEffect(() => {
    if (productId) {
      dispatch(getSingleProduct(productId));
    }
  }, [productId]);

  useEffect(() => {
    if (productData) {
      if (productData.data) {
        setSingleProduct(productData.data);
        setProductCategory(productData.data.category);
        setProductAge(productData.data.age);
        setAllProductReviws(productData.data.reviews);
        setProductLoading(false);
      }
    }
  }, [productData]);

  //add or remove product from wishlist
  const handelAddToWishlist = (productId) => {
    if (heart) {
      dispatch(removeProductFromWishlist(productId));
      setHeart(false);
    } else {
      dispatch(
        addToWishlist({
          productId,
        })
      );
      setHeart(true);
    }
  };

  //get logged user wishlist
  useEffect(() => {
    dispatch(getLoggedUser());
    dispatch(getLoggedUserWishlist());
  }, []);

  useEffect(() => {
    if (allWishlist) {
      if (allWishlist.status === "success") {
        if (allWishlist.data) {
          allWishlist.data.map((wish) => {
            if (wish._id === productId) {
              setHeart(HeartRed);
            }
          });
        }
      }
    }
  }, [allWishlist]);

  useEffect(() => {
    if (getLogedUserData) {
      setLoggedUser(getLogedUserData.status);
    }
  }, [getLogedUserData]);

  const handelCart = async (productId) => {
    setBtnLoaderStatus(true);
    if (loggedUser === 401) {
      notifyError("من فضلك قم بتسجيل الدخول أولاً");
      setBtnLoaderStatus(false);
    } else if (loggedUser === 200) {
      if (selectedSize) {
        await dispatch(
          addToCart({
            productId: productId,
            color: selectedSize,
          })
        );
        notifySuccess("تم إضافة المنتج إلى العربة");
        setBtnLoaderStatus(false);
      } else {
        notifyError("من فضلك قم بتحديد المقاس أولاً");
        setBtnLoaderStatus(false);
      }
    }
  };

  return (
    <div className="product-page">
      <Helmet>
        <title>{`SHORTCUT | ${singleProduct.description}  (${productId})`}</title>
      </Helmet>
      <div className="container">
        {productLoading ? (
          <Spiner />
        ) : (
          <div className="product-page-wraper">
            <div className="product-slider">
              <Slider
                images={singleProduct.images}
                imgCover={singleProduct.imageCover}
              />
            </div>
            <div className="product-details">
              <h2 className="product-name">{singleProduct.title}</h2>
              <ul className="product-info">
                <li>
                  <p>{singleProduct.title}</p>: الباركود
                </li>
                <li>
                  <p>
                    <small>{singleProduct.quantity}</small>in Stock
                  </p>
                  : الإتاحة
                </li>
                <li>
                  <p>{singleProduct.age}</p>: العمر
                </li>
              </ul>
              <div className="product-price">
                <p className="old-price">LE {singleProduct.price}.00</p>
                <p className="new-price">
                  LE {singleProduct.priceAfterDiscount}.00
                </p>
              </div>
              <div className="sizes">
                <h5>: المقاسات</h5>
                <ul>
                  {singleProduct.availableSizes
                    ? singleProduct.availableSizes.map((size, index) => (
                        <li
                          style={
                            sizeIndex === index
                              ? { background: "#000", color: "#FFF" }
                              : { background: "#ececec", color: "#3c3c3c" }
                          }
                          key={index}
                          onClick={() => {
                            setSelectedSize(size);
                            setSizeIndex(index);
                          }}
                        >
                          {size}
                        </li>
                      ))
                    : null}
                </ul>
              </div>
              {singleProduct.availability ? (
                <div className="product-option">
                  <div
                    className="add-to-fav"
                    onClick={() => handelAddToWishlist(productId)}
                  >
                    <img
                      src={heart ? HeartRed : Heart}
                      alt="heart"
                      loading="lazy"
                    />
                  </div>
                  <div
                    className="add-to-cart"
                    onClick={() => handelCart(productId)}
                  >
                    {btnLoaderStatus ? <BtnLoader /> : "أضف لعربة التسوق"}
                  </div>
                </div>
              ) : (
                <div className="product-option">
                  <div className="product-not-available">المنتج غير متوفر</div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="description-reviews">
        <ul className="description-reviews-nav">
          <li
            className={boxId === "2" ? "active" : ""}
            onClick={() => setBoxId("2")}
          >
            المراجعات
          </li>
          <li
            className={boxId === "1" ? "active" : ""}
            onClick={() => setBoxId("1")}
          >
            وصف المُنتج
          </li>
        </ul>
        <div className="boxs-container">
          <div
            className="desc-box"
            style={{ display: boxId === "1" ? "block" : "none" }}
          >
            <p>{singleProduct.description}</p>
          </div>
          <div
            className="reviews-box"
            style={{ display: boxId === "2" ? "block" : "none" }}
          >
            <div className="add-new-reviews">
              {/* <p className="no-reviews">No Reviews Yet</p> */}
              <span
                className="write-review"
                onClick={() => setToggleShowReviewBox(!toggleShowReviewBox)}
              >
                كتابة مراجعة
              </span>
              <div
                className="review-box"
                style={{ display: toggleShowReviewBox ? "block" : "none" }}
              >
                <div className="review-rating">
                  <ReactStars
                    count={5}
                    onChange={ratingChanged}
                    size={30}
                    isHalf={true}
                    emptyIcon={<i className="far fa-star"></i>}
                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                    fullIcon={<i className="fa fa-star"></i>}
                    activeColor="#FF9800"
                  />
                  <h5>تقيم</h5>
                </div>
                <textarea
                  maxLength={200}
                  placeholder="كتابة مراجعة"
                  value={review}
                  onChange={(e) => setReview(e.target.value)}
                ></textarea>
                <button className="submit" onClick={handelWriteReview}>
                  إضافة مراجعة
                </button>
              </div>
            </div>
            {allProductReviews.length > 0 ? (
              <div className="reviews-wraper">
                {allProductReviews.map((item, index) => (
                  <div className="review" key={index}>
                    <div className="user">
                      <h6>{item.user.name}</h6>
                      <img src={User} alt="user-img" />
                    </div>
                    <div className="rating">
                      <ReactStars
                        count={5}
                        onChange={ratingChanged}
                        size={30}
                        isHalf={true}
                        emptyIcon={<i className="far fa-star"></i>}
                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        activeColor="#8f6B29"
                        edit={false}
                        value={item.rating}
                      />
                    </div>
                    <p>{item.review}</p>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <LookLike
        productAge={productAge}
        productCategory={productCategory}
        productId={productId}
      />
    </div>
  );
};

export default ProductPage;
