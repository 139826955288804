import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/main.css'
import store from './redux/store'
import {Provider} from 'react-redux'
import { HelmetProvider } from 'react-helmet-async';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <BrowserRouter>
        <HelmetProvider>
            <App />
        </HelmetProvider>
        </BrowserRouter>
    </Provider>
);

