import React, { useEffect, useState } from 'react'
import ReactPaginate from "react-paginate";

const Pagination = ({pageResult, onPress}) => {

    const [CountOfPage, setCountOfPage] = useState("")

    const handlePageClick = (data) => {
        onPress(data.selected + 1)
    };

    useEffect(()=>{
        if(pageResult){
            setCountOfPage(pageResult.numberOfPages)
        }
    }, [pageResult])

    return (
        <ReactPaginate
            breakLabel="..."
            nextLabel="التالى"
            onPageChange={handlePageClick}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            pageCount={+CountOfPage}
            previousLabel="السابق"
            containerClassName={"pagination justify-content-center p-3"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            nextClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
        />
    )
}

export default Pagination