import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductCart from "../../components/products/ProductCart";
import { applyCoupon, getCartItems } from "../../redux/actions/cartAction";
import { Link } from "react-router-dom";
import Spiner from "../../utilities/Spiner";
import { useNavigate } from "react-router-dom";
import { getLoggedUser } from "../../redux/actions/authAction";
import { toast } from "react-toastify";
import BtnLoader from "../../utilities/BtnLoader";
import { Helmet } from "react-helmet-async";

const Cart = () => {
  const [cartProducts, setCartProducts] = useState([]);
  const [total, setTotla] = useState("");
  const [totalDiscount, setTotalDiscount] = useState("");
  const [couponName, setCouponName] = useState("");
  const [cartLoading, setCartLoading] = useState(true);
  const [btnLoaderStatus, setBtnLoaderStatus] = useState(false);

  const dispatch = useDispatch();
  const cartItemsData = useSelector((state) => state.cartReducer.cartItems);
  const applyCouponData = useSelector((state) => state.cartReducer.applyCoupon);
  const loggedUserData = useSelector((state) => state.authReducer.getMe);

  const navigate = useNavigate();
  const notifyError = (msg) => toast.error(msg);
  const notifySuccess = (msg) => toast.success(msg);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(getCartItems());
    }
  }, []);

  const handelApplyCoupon = () => {
    setBtnLoaderStatus(true);
    dispatch(applyCoupon({ couponName }));
  };

  useEffect(() => {
    if (applyCouponData) {
      setBtnLoaderStatus(false);
      if (applyCouponData.status == "success") {
        if (applyCouponData.data) {
          setTotalDiscount(applyCouponData.data.totalAfterDiscount);
          notifySuccess("تم تطبيق الكوبون بنجاح");
        }
      } else if (applyCouponData.status == 400) {
        if (applyCouponData.data) {
          notifyError("هذا الكوبون خطأ أو مٌنتهي الصلاحية");
        }
      }
    }
  }, [applyCouponData]);

  useEffect(() => {
    if (cartItemsData) {
      if (cartItemsData.status == "success") {
        if (cartItemsData.data) {
          setTotla(cartItemsData.data.totalCartPrice);
          setCartProducts(cartItemsData.data.products);
          setCartLoading(false);
        }
      }
    }
  }, [cartItemsData]);

  useEffect(() => {
    dispatch(getLoggedUser());
  }, []);

  useEffect(() => {
    if (loggedUserData) {
      if (loggedUserData.status === 200) {
        if (loggedUserData.data) {
          if (loggedUserData.data.data.role !== "user") {
            navigate("/");
            notifyError("غير مسموح بهذا الإجراء لمدير الموقع");
          }
        }
      } else if (loggedUserData.status === 401) {
        navigate("/");
        notifyError("من فضلك قم بتسجيل الدخول أولاً");
      }
    }
  }, [loggedUserData]);

  return (
    <div className="cart-page">
      <Helmet>
        <title>{`SHORTCUT | سلة المٌشتريات`}</title>
      </Helmet>
      <div className="container">
        <div className="cart-page-wraper">
          <h2 className="page-title">عربتك للتسوق</h2>
          {cartLoading ? (
            <Spiner />
          ) : cartProducts.length > 0 ? (
            <div className="cart-container">
              <div className="summery-order">
                <h2>ملخص طلبك</h2>
                <div className="summery-wraper">
                  <div className="total-before-discount">
                    <p>{Math.round(total)}.00 LE</p>
                    <h4>:الإجمالي</h4>
                  </div>
                  <div className="cupon">
                    <h4>كود الخصم</h4>
                    <input
                      type="text"
                      value={couponName}
                      placeholder="كود الخصم"
                      onChange={(e) => setCouponName(e.target.value)}
                    />
                    <button onClick={handelApplyCoupon}>
                      {btnLoaderStatus ? <BtnLoader /> : "تطبيق الخصم"}
                    </button>
                  </div>
                  {totalDiscount !== "" ? (
                    <div className="total-after-discount">
                      <p>{Math.round(totalDiscount)}.00 LE</p>
                      <h4>:الإجمالي بعد الخصم</h4>
                    </div>
                  ) : null}
                  <div className="complete-order">
                    <Link to="/checkout">إكمال الطلب</Link>
                  </div>
                  <div className="complete-order">
                    <Link to="" onClick={() => window.history.back()}>
                      استمر في التسوق
                    </Link>
                  </div>
                </div>
              </div>
              <div className="items-wraper">
                <div className="items-titles">
                  <div>
                    <h4>الإجمالي</h4>
                    <h4>الكمية</h4>
                    <h4>السعر</h4>
                  </div>
                  <h4>المنتج</h4>
                </div>
                <div className="products-container">
                  {cartProducts.length > 0
                    ? cartProducts
                        .reverse()
                        .map((product, index) => (
                          <ProductCart product={product} key={index} />
                        ))
                    : null}
                </div>
              </div>
            </div>
          ) : (
            <p className="no-items">
              لم تقم بإضافة منتجات إلى عربة التسوق حتى الأن
              <Link to="/summer-men-collection">أنتقل للتسوق</Link>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cart;
