import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link} from 'react-router-dom'
import Logo from "../images/imgs/shortcut.png";
import User from "../images/svg/user.svg";
import Search from "../images/svg/search.svg";
import SearchGray from "../images/svg/search-gray.svg";
import Cart from "../images/svg/cart.svg";
import Car from "../images/svg/car.svg";
import Menu from "../images/svg/menu.svg";
import Login from "../images/svg/login.svg";
import Logout from "../images/svg/logout.svg";
import Close from "../images/svg/close.svg";
import Plus from "../images/svg/plus.svg";
import Minus from "../images/svg/minus-white.svg";
import Dashboard from "../images/svg/dashboard.svg";
import { getAllCategory } from "../redux/actions/categoryAction";
import {
  getLoggedUser,
  loginUser,
  userLogout,
} from "../redux/actions/authAction";
import { getCartItems } from "../redux/actions/cartAction";
import { toast } from "react-toastify";
import BtnLoader from "./BtnLoader";

const Navbar = () => {
  const [userMenu, setUserMenu] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [plusImg, setPluseImg] = useState(true);
  const [showCatMobMenu, setShowCatMobMenu] = useState(false);
  const [userData, setUserData] = useState([]);
  const [cartItems, setCartItems] = useState([]);

  const [catMenu, setCatMenu] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [allCategories, setAllCategories] = useState([]);
  const [btnLoaderStatus, setBtnLoaderStatus] = useState(false)


  const dispatch = useDispatch();
  const loggedUser = useSelector((state) => state.authReducer.getMe);
  const loginUserData = useSelector((state) => state.authReducer.loginUser);
  const categories = useSelector((state) => state.categoryReducer.categories);
  const cartItemsData = useSelector((state) => state.cartReducer.cartItems);

  const notifyError = (msg) => toast.error(msg);

  const handelLogin = async (e) => {
    e.preventDefault();
    setBtnLoaderStatus(true)
    const data = {
      email,
      password,
    };
    await dispatch(loginUser(data));
  };

  const handelLogout = () => {
    dispatch(userLogout());
    localStorage.removeItem("token");
    setUserMenu(!userMenu);
    window.location.href = "/";
  };

  const handelLogoutMobile = () => {
    dispatch(userLogout());
    localStorage.removeItem("token");
    setShowMenu(!showMenu);
    window.location.href = "/";
  };

  useEffect(() => {
    if (cartItemsData) {
      if (cartItemsData.status === "success") {
        if (cartItemsData.data) {
          setCartItems(cartItemsData.data.products);
        }
      }
    }
  }, [cartItemsData]);

  useEffect(() => {
    if (loginUserData) {
      setBtnLoaderStatus(false)
      if (loginUserData.status === 500) {
        if (loginUserData.data) {
          notifyError(loginUserData.data.message);
        }
      } else if (loginUserData.status === 400) {
        if (loginUserData.data) {
          loginUserData.data.errors.map((err) => notifyError(err.msg));
        }
      } else if (loginUserData.status === 200) {
        if (loginUserData.data) {
          if (loginUserData.data.token)
            localStorage.setItem("token", loginUserData.data.token);
          window.location.href = "/";
        }
      }
    }
  }, [loginUserData]);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(getLoggedUser());
    }
    if (localStorage.getItem("token")) {
      dispatch(getCartItems());
    }
  }, []);

  useEffect(() => {
    if (loggedUser) {
      if (loggedUser.status === 200) {
        if (loggedUser.data) {
          setUserData(loggedUser.data.data);
        }
      }
    }
  }, [loggedUser]);

  useEffect(() => {
    dispatch(getAllCategory());
  }, []);

  useEffect(() => {
    if (categories) {
      if (categories.status === 200) {
        if (categories.data) {
          setAllCategories(categories.data.data);
        }
      }
    }
  }, [categories]);

  return (
    <nav className="navbar">
      <div
        className="search-form"
        style={{ display: showSearch ? "block" : "none" }}
      >
        <form method="GET" action="/search">
          <img
            src={Close}
            alt="close-menu"
            onClick={() => setShowSearch(false)}
          />
          <input
            name="keyword"
            id="keyword"
            type="text"
            placeholder="....أبحث عن الأصناف"
          />
          <button className="submit-search-btn" type="submit">
            <img
              className="search-icon-input"
              src={SearchGray}
              alt="search-input"
            />
          </button>
        </form>
      </div>
      <div className="container">
        <div className="left-icons">
          <img
            className="menu"
            src={Menu}
            alt="bar-menu"
            onClick={() => setShowMenu(!showMenu)}
          />
          <img
            className="search-mobile"
            src={Search}
            alt="search"
            onClick={() => setShowSearch(true)}
          />
        </div>
        <div className="logo">
          <a href="/">
            <img src={Logo} alt="logo" />
          </a>
        </div>
        <div className="right-icons">
          {localStorage.getItem("token") ? (
            <a href="/profile">
              <img className="user" src={User} alt="user" />
            </a>
          ) : (
            <img
              className="user"
              src={User}
              alt="user"
              onClick={() => setShowUserMenu(!showUserMenu)}
            />
          )}
          <a href="/cart">
            <img className="cart" src={Cart} alt="cart" />
            {localStorage.getItem("token") ? (
              <small className="num-cart">
                {cartItems && cartItems.length}
              </small>
            ) : null}
          </a>
        </div>
        <ul className="list-items">
          <li className="item categories-menu">
            <Link
              className="nav-link"
              to=""
              onClick={(e) => {
                e.preventDefault();
                setCatMenu(!catMenu);
              }}
            >
              كل الأصناف
            </Link>
            <ul
              className="all-categories"
              style={{ display: catMenu ? "flex" : "none" }}
            >
              {allCategories
                ? allCategories.map((cat, index) => (
                    <li key={index}>
                      <a
                        href={`/category/${cat._id}`}
                        onClick={() => setCatMenu(false)}
                      >
                        {cat.name}
                      </a>
                    </li>
                  ))
                : null}
            </ul>
          </li>
          <li className="item">
            <span className="badge sale">خصم</span>
            <a className="nav-link" href={"/less100"}>
              أقل من 100
            </a>
          </li>
          <li className="item">
            <a className="nav-link" href={"/winter"}>
              منتجات الشتاء
            </a>
          </li>
          <li className="item">
            <span className="badge">جديد</span>
            <a className="nav-link" href={"/new-arrived"}>
              وصل حديثاً
            </a>
          </li>
          <li className="item">
            <span className="badge sale">خصم</span>
            <a className="nav-link" href={"/offers"}>
              عروض
            </a>
          </li>
          <li className="item">
            <a className="nav-link" href={"/winter-men-collection"}>
              رجال
            </a>
          </li>
        </ul>
        <div className="nav-icons">
          <a href="/cart">
            <img className="icon" src={Car} alt="cart" />
            {localStorage.getItem("token") ? (
              <span className="num-cart">{cartItems && cartItems.length}</span>
            ) : null}
          </a>
          <div className="user">
            <img
              className="icon"
              src={User}
              alt="user"
              onClick={() => setUserMenu(!userMenu)}
            />
          </div>
          <div className="search">
            <img
              className="icon"
              src={Search}
              alt="search"
              onClick={() => setShowSearch(true)}
            />
          </div>
          {localStorage.getItem("token") ? (
            <ul
              className="user-menu"
              style={{ display: userMenu ? "block" : "none" }}
            >
              {userData.role !== "admin" ? (
                <li>
                  <a href="/profile" onClick={() => setUserMenu(!userMenu)}>
                    الصفحة الشخصية
                    <img className="user-menu-icon" src={User} alt="login" />
                  </a>
                </li>
              ) : (
                <li>
                  <a href="/admin" onClick={() => setUserMenu(!userMenu)}>
                    لوحة التحكم
                    <img
                      className="user-menu-icon"
                      src={Dashboard}
                      alt="dashboard"
                    />
                  </a>
                </li>
              )}
              <li>
                <a href="" onClick={() => handelLogout()}>
                  تسجيل الخروج
                  <img className="user-menu-icon" src={Logout} alt="logout" />
                </a>
              </li>
            </ul>
          ) : (
            <ul
              className="user-menu"
              style={{ display: userMenu ? "block" : "none" }}
            >
              <li>
                <a href="/login" onClick={() => setUserMenu(!userMenu)}>
                  تسجيل الدخول
                  <img className="user-menu-icon" src={Login} alt="login" />
                </a>
              </li>
              <li>
                <a href="/signup" onClick={() => setUserMenu(!userMenu)}>
                  إنشاء حساب
                  <img className="user-menu-icon" src={User} alt="signup" />
                </a>
              </li>
            </ul>
          )}
        </div>
      </div>
      <div className={`mobile-menu ${showMenu ? "show" : "hidden"}`}>
        <ul className="links-wraper">
          <li className="close-menu" onClick={() => setShowMenu(false)}>
            X
          </li>
          <li>
            <Link to="/" onClick={() => setShowMenu(false)}>
              الصفحة الرئيسية
            </Link>
          </li>
          <li>
            <a
              href="/winter-men-collection"
              onClick={() => setShowMenu(false)}
            >
              رجال
            </a>
          </li>
          <li>
            <a
              href="/offers"
              onClick={() => setShowMenu(false)}
            >
              عروض
            </a>
          </li>
          <li>
            <a href="/less100" onClick={() => setShowMenu(false)}>
              أقل من 100
            </a>
          </li>
          <li>
            <a href="/less200" onClick={() => setShowMenu(false)}>
              أقل من 200
            </a>
          </li>
          <li>
            <Link
              to=""
              className="all-cat"
              onClick={(e) => {
                e.preventDefault();
                setShowCatMobMenu(!showCatMobMenu);
                setPluseImg(!plusImg);
              }}
            >
              كل الأصناف
              <img src={plusImg ? Plus : Minus} alt="plus" />
            </Link>
          </li>
          <ul
            className="all-category-mobile"
            style={showCatMobMenu ? { display: "block" } : { display: "none" }}
          >
            {allCategories.length > 0
              ? allCategories.map((cat, index) => (
                  <li key={index}>
                    <a
                      href={`/category/${cat._id}`}
                      onClick={() => setShowMenu(false)}
                    >
                      {cat.name}
                    </a>
                  </li>
                ))
              : null}
          </ul>
          <li>
            <a href="new-arrived" onClick={() => setShowMenu(false)}>
              وصل حديثاً
            </a>
          </li>
          <li>
            <a href="/winter" onClick={() => setShowMenu(false)}>
              منتجات الشتاء
            </a>
          </li>
          <li>
            <a href="/summer" onClick={() => setShowMenu(false)}>
              منتجات الصيف
            </a>
          </li>
          <li className="user-mobile">
            {localStorage.getItem("token") ? (
              userData.role !== "admin" ? (
                <div className="user-mobile-wraper">
                  <a href="/profile" onClick={() => setShowMenu(false)}>
                    <img className="user-menu-icon" src={User} alt="profile" />
                    الصفحة الشخصية
                  </a>
                  <a href="" onClick={() => handelLogoutMobile()}>
                    <img className="user-menu-icon" src={Logout} alt="logout" />
                    تسجيل الخروج
                  </a>
                </div>
              ) : (
                <div className="user-mobile-wraper">
                  <a href="/admin" onClick={() => setShowMenu(false)}>
                    <img
                      className="user-menu-icon"
                      src={Dashboard}
                      alt="profile"
                    />
                    لوحة التحكم
                  </a>
                  <a href="" onClick={() => handelLogoutMobile()}>
                    <img className="user-menu-icon" src={Logout} alt="logout" />
                    تسجيل الخروج
                  </a>
                </div>
              )
            ) : (
              <div className="user-mobile-wraper">
                <a href="/login" onClick={() => setShowMenu(false)}>
                  <img className="user-menu-icon" src={Login} alt="login" />
                  تسجيل الدخول
                </a>
                <a href="/signup" onClick={() => setShowMenu(false)}>
                  <img className="user-menu-icon" src={User} alt="signup" />
                  إنشاء حساب
                </a>
              </div>
            )}
          </li>
        </ul>
      </div>

      <div className={`user-right-menu ${showUserMenu ? "show" : "hidden"}`}>
        <div className="user-right-wraper">
          <h2>
            <span onClick={() => setShowUserMenu(!showUserMenu)}>X</span>تسجيل
            الدخول
          </h2>
          <form>
            <label htmlFor="email">
              <span>*</span>رقم الهاتف
            </label>
            <input
              type="email"
              id="email"
              placeholder="أدخل رقم الهاتف"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="password">
              <span>*</span>كلمة المرور
            </label>
            <input
              type="password"
              id="password"
              placeholder="أدخل كلمة المرور"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              className="submit"
              type="submit"
              id="submit"
              value="تسجيل الدخول"
              onClick={(e) => handelLogin(e)}
            >
              {btnLoaderStatus ? <BtnLoader /> : "تسجيل الدخول"}
            </button>
          </form>
          {/* <Link to="/forgot-password" className="forget-password" onClick={() => setShowUserMenu(!showUserMenu)}>
            نسيت كلمة المرور؟
          </Link> */}
          <div className="signup">
            <a href="/signup" onClick={() => setShowUserMenu(!showUserMenu)}>
              إنشاء حساب
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
