import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addAddress,
  getAllAddAddress,
  removeAddress,
} from "../../redux/actions/addressAction";
import ProfileNavigation from "../../utilities/ProfileNavigation";
import Home from "../../images/svg/home.svg";
import Trash from "../../images/svg/trash-white.svg";
import Spiner from '../../utilities/Spiner'
import { useNavigate } from "react-router-dom";
import { getLoggedUser } from "../../redux/actions/authAction";
import { toast } from 'react-toastify';
import { Helmet } from "react-helmet-async";

const AddAddress = () => {
  const [alias, setAlias] = useState("");
  const [details, setDetails] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [username, setUsername] = useState("");
  // const [email, setEmail] = useState("");

  const [allUserData, setAllUserData] = useState([]);
  const [addressLoading, setAddressLoading] = useState(true);

  const dispatch = useDispatch();
  const addressData = useSelector((state) => state.addressReducer.address);
  const userAddressData = useSelector(
    (state) => state.addressReducer.allAddress
  );

  const loggedUserData = useSelector(state=>state.authReducer.getMe)

  const navigate = useNavigate()

  const notifyError = (msg) => toast.error(msg);
  const notifysuccess = (msg) => toast.success(msg);

  useEffect(() => {
    dispatch(getLoggedUser())
    dispatch(getAllAddAddress());
  }, []);

  useEffect(() => {
    if (userAddressData) {
      if (userAddressData.status === "success") {
        if (userAddressData.data) {
          setAllUserData(userAddressData.data);
          setAddressLoading(false);
        }
      }
    }
  }, [userAddressData]);

  useEffect(() => {
    if (addressData) {
      if (addressData.status === "success") {
        notifysuccess("تم إضافة العنوان بنجاح");
        setCity("")
        setDetails("")
        setAlias("")
        setPhone("")
        setPostalCode("")
        setUsername("")
        // setEmail("")
      }
    }
  }, [addressData]);

  const handelAddAddress = (e) => {
    e.preventDefault();
    if (
      alias !== "" &&
      details !== "" &&
      phone !== "" &&
      city !== "" &&
      postalCode !== "" && username !== ""
    ) {
      dispatch(
        addAddress({
          alias,
          details,
          phone,
          city,
          postalCode,
          username,
          // email,
        })
      );
    }else{
      notifyError('من فضلك قم بإستكمال البيانات')
    }
  };

  useEffect(()=>{
    if(loggedUserData){
      if(loggedUserData.status === 200){
        if(loggedUserData.data){
          if(loggedUserData.data.data.role !== "user"){
            navigate('/')
          }
        }
      }else if(loggedUserData.status === 401){
        navigate('/')
      }
    }
  },[loggedUserData])


  return (
    <div className="add-address-page">
      <Helmet>
        <title>{`SHORTCUT | إضافة عنوان جديد`}</title>
      </Helmet>
      <h2 className="title">العناوين الخاصة بك</h2>
      <ProfileNavigation />
      <div className="add-address-containers">
        <h3>إضافة عنوان جديد</h3>
        <form>
          <input
            type="text"
            value={alias}
            placeholder="إضافة اسم العنوان (المنزل،العمل،..)"
            onChange={(e) => setAlias(e.target.value)}
            required
          />
          <input
            type="text"
            value={details}
            placeholder="إضافة تفاصبل العنوان"
            onChange={(e) => setDetails(e.target.value)}
            required
          />
          <input
            type="text"
            value={city}
            placeholder="إضافة المحافظة"
            onChange={(e) => setCity(e.target.value)}
            required
          />
          <input
            type="number"
            value={phone}
            placeholder="إضافة رقم الهاتف"
            onChange={(e) => setPhone(e.target.value)}
            required
          />
          {/* <input
            type="email"
            value={email}
            placeholder="إضافة البريد الألكتروني"
            onChange={(e) => setEmail(e.target.value)}
            required
          /> */}
          <input
            type="text"
            value={username}
            placeholder="إضافة اسم المُستخدم"
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <input
            type="text"
            value={postalCode}
            placeholder="إضافة الكود البريدي"
            onChange={(e) => setPostalCode(e.target.value)}
            required
          />
          <input
            className="submit"
            type="submit"
            value="إضافة عنوان"
            onClick={(e) => handelAddAddress(e)}
          />
        </form>
      </div>

      <div className="add-address-containers">
        <h3>كل العناوين الخاصة بك</h3>
        <div className="adresses-wraper">
          {addressLoading ? (
            <Spiner />
          ) : allUserData.length > 0 ? (
            allUserData.map((address, index) => (
              <div className="address" key={index}>
                <img
                  className="trash-img"
                  src={Trash}
                  alt="delete-address"
                  onClick={() => {
                    dispatch(removeAddress(address._id));
                    window.location.reload();
                  }}
                />
                <div className="address-details">
                  <span>
                    <p>{address.alias}</p>
                    <h5>:العنوان</h5>
                  </span>
                  <span>
                    <p>{address.details}</p>
                    <h5>:تفاصيل العنوان</h5>
                  </span>
                  <span>
                    <p>{address.phone}</p>
                    <h5>:رقم الهاتف</h5>
                  </span>
                  <span>
                    <p>{address.city}</p>
                    <h5>:المحافظة</h5>
                  </span>
                  {/* <span>
                    <p>{address.email}</p>
                    <h5>:البريد الألكتروني</h5>
                  </span> */}
                  <span>
                    <p>{address.username}</p>
                    <h5>:اسم المٌستخدم</h5>
                  </span>
                </div>
                <img className="home-img" src={Home} alt="address" />
              </div>
            ))
          ) : (
            <p className="no-items">لا يوجد أي عناوين حتى الأن</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddAddress;
