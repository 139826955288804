import { CREATE_USER, LOGIN_USER, GET_ME, LOGME_OUT, FORGOT_PASSWORD, RESET_CODE, RESET_PASSWORD } from "../types"

const initState = {
    signup: [],
    loginUser: [],
    getMe: [],
    logout: [],
    forgotPassword: [],
    resetCode: [],
    resetPassword: []
}

const authReducer = (state = initState, action)=>{
    switch(action.type)
    {
        case CREATE_USER:
            return {signup: action.payload}
        case LOGIN_USER:
            return {loginUser: action.payload}   
        case GET_ME:
            return {getMe: action.payload}  
        case LOGME_OUT:
            return {logout: action.payload}
        case FORGOT_PASSWORD:
            return {forgotPassword: action.payload}
        case RESET_CODE:
            return {resetCode: action.payload}
        case RESET_PASSWORD:
            return {resetPassword: action.payload}                   
        default:
            return state    
    }
}

export default authReducer