import React from "react";
import { Helmet } from "react-helmet-async";

const TeamPrivacyPolicy = () => {
  return (
    <div className="static-page">
      <Helmet>
        <title>{`SHORTCUT | سياسة الخصوصية`}</title>
      </Helmet>
      <div className="static-page-container">
        <h1>سياسة خصوصية شورت كت</h1>
        <ul>
          <li>- ما هي المعلومات الشخصية التي نجمعها؟</li>
          <li>- كيف نستخدم ملفات تعريف الارتباط؟</li>
          <li>- الأمان</li>
          <li>- حقوقك</li>
          <li>- تغييرات في سياسة الخصوصية الخاصة بنا</li>
          <li>-معلومات للتواصل</li>
        </ul>
        <h5>سياسة الخصوصية</h5>
        <p>
          نحن ملتزمون بحماية واحترام خصوصية جميع زوار ومستخدمي SHORTCUTMENSTORE.COM ،
          يرجى قراءة ما يلي بعناية لفهم سياستنا وممارساتنا المتعلقة بمعلوماتك
          الشخصية التي يتم جمعها من خلال الموقع ، وطريقة عمل الموقع. سنستخدم فقط
          معلوماتك الشخصية التي يتم جمعها من خلال موقع الويب هذا وفقًا لسياسة
          الخصوصية هذه وقوانين حماية البيانات الحالية المعمول بها.
        </p>
        <p>
          ما هي المعلومات الشخصية التي نجمعها؟نحن لا نجمع أي معلومات شخصية عنك
          على موقعنا الإلكتروني إلا إذا اخترت تقديمها لنا طوعًا. إذا قررت تقديم
          طلب معنا ، فهناك بعض المعلومات الشخصية التي سنطلبها منك من أجل معالجة
          طلبك. نحن نعرّف "المعلومات الشخصية" على أنها معلومات فريدة بالنسبة
          إليك وقد تتضمن اسمك وعنوان التسليم
         وعنوان الفواتير وعنوان البريد الإلكتروني ورقم الهاتف.
        </p>
        <p>
          قد نجمع أيضًا البيانات التالية عنك لمساعدتنا على تحسين خدماتنا لك:
        </p>
        <p>
          المعلومات التي تقدمها من خلال ملء الاستمارات على موقعنا. يتضمن هذا
          المعلومات المقدمة في وقت التسجيل لاستخدام موقعنا الإلكتروني ،
          والاشتراك في أي خدمة نقدمها أو ننشرها أو لطلب المزيد من الخدمات. قد
          نطلب منك أيضًا معلومات عند دخولك في مسابقة أو عرض ترويجي ، وعندما تبلغ
          عن مشكلة في موقعنا الإلكتروني.
        </p>
        <p>
          إذا اتصلت بنا ، فقد نحتفظ بسجل لتلك المراسلات. قد نطلب منك أيضًا إكمال
          الاستطلاعات التي نستخدمها لأغراض البحث ، ولكن ليس عليك الرد
          عليها.تفاصيل المعاملات التي تجريها من خلال موقعنا الإلكتروني وتنفيذ
          طلباتك. تفاصيل زياراتك لموقعنا بما في ذلك ، على سبيل المثال لا الحصر ،
          بيانات المرور وبيانات الموقع وقوائم الويب وبيانات الاتصال الأخرى ،
          سواء كان ذلك مطلوبًا لأغراض الفواتير الخاصة بنا أو غير ذلك من الموارد
          الموجودة على موقعنا.
        </p>
        <h5>كيف نستخدم ملفات تعريف الارتباط؟</h5>
        <p>
          يعرض متصفح الويب الخاص بك "ملفات تعريف الارتباط" التي تسمى أيضًا
          "cookies" والتي ، إذا سمحت باستخدامها ، نقم بتخزين كميات
          صغيرة من البيانات على جهاز الكمبيوتر الخاص بك عندما تزور موقعنا
          الإلكتروني. لا تحتوي ملفات تعريف الارتباط على أي معلومات شخصية عنك ،
          وبالتالي لا يمكن استخدامها لتحديد هويتك شخصيًا. ومع ذلك ، تساعدنا
          ملفات تعريف الارتباط في تتبع ميزاتنا التي تفضلها وتجعل استخدام الموقع
          الإلكتروني أسهل. نحن نستخدم ملفات تعريف الارتباط من أجل الاحتفاظ
          بمحتويات عربة التسوق الخاصة بك أثناء تصفحك لموقع الويب.
        </p>
        <h5>الأمان</h5>
        <p>
          نحن نستخدم برنامج Secure SOCKET LAYER SOFTWARE (SSL) لتشفير جميع
          معلوماتك الشخصية بما في ذلك اسمك وعنوانك. تمنعك هذه التقنية من الكشف
          عن المعلومات الشخصية عن غير قصد باستخدام اتصال غير آمن.
        </p>
        <h5>حقوقك</h5>
        <p>
          يحق لك أن تطلب منا عدم معالجة معلوماتك الشخصية لأغراض التسويق. سنعلمك
          (قبل جمع البيانات الخاصة بك) إذا كنا نعتزم استخدام بياناتك لهذه
          الأغراض أو إذا كنا نعتزم الكشف عن معلوماتك إلى أي طرف ثالث لهذه
          الأغراض. إذا كنت لا ترغب في تلقي معلومات التسويق منا ، فلديك خيار
          "الخروج". سيتم تفصيل تعليمات واضحة حول كيفية الخروج من كل منطقة على
          وجه التحديد (للحصول على معلومات في الجزء السفلي من نشرتنا الإخبارية
          الإلكترونية ، سيكون لديك خيار "إلغاء الاشتراك في نموذج البريد
          الإلكتروني الخاص بنا ، انقر هنا") بدلا من ذلك ، من فضلك دعنا نعرف في
          الكتابة.
        </p>{" "}
        <h5>التغييرات في سياسة الخصوصية الخاصة بنا</h5>
        <p>
          .سيتم نشر أي تغييرات قد نجريها على سياسة الخصوصية الخاصة بنا في
          المستقبل على هذه الصفحة ، وعند الاقتضاء ، سيتم إعلامك بها عن طريق
          البريد الإلكتروني.
        </p>
        <h5>معلومات للتواصل</h5>
        <p>
          .إذا كان لديك أي أسئلة حول سياسة الخصوصية هذه أو ممارسات الخصوصية
          الخاصة بنا ، فيرجى مراسلتنا عبر البريد الإلكتروني على
          INFO@SHORTCUTMENSTORE.COM. يرجى تضمين عنوان URL .الخاص بالموقع الإلكتروني و /
          أو الصفحة المحددة داخل موقع الويب المتضمن في طلبك.
        </p>
      </div>
    </div>
  );
};

export default TeamPrivacyPolicy;
