import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Link} from 'react-router-dom'
import {loginUser} from '../../redux/actions/authAction'
import { useNavigate } from "react-router-dom";
import { getLoggedUser } from "../../redux/actions/authAction";
import { toast } from 'react-toastify';
import BtnLoader from '../../utilities/BtnLoader';
import { Helmet } from 'react-helmet-async';

const Login = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const [role, setRole] = useState("");
  const [btnLoaderStatus, setBtnLoaderStatus] = useState(false)


  const loginUserData = useSelector(state=>state.authReducer.loginUser)
  const loggedUserData = useSelector(state=>state.authReducer.getMe)
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const notifyError = (msg) => toast.error(msg);

  const handelSubmit = async(e)=>{
    e.preventDefault()
    setBtnLoaderStatus(true)
    const loginData = {
      email,
      password,
    }
    await dispatch(loginUser(loginData))
  }

  useEffect(()=>{
    if(loginUserData){
      setBtnLoaderStatus(false)
      if(loginUserData.status === 500){
        if(loginUserData.data){
          notifyError(loginUserData.data.message)
        }
      }else if(loginUserData.status === 400){
        if(loginUserData.data){
          loginUserData.data.errors.map((error)=>(
            notifyError(error.msg)
          ))
        }
      }else if(loginUserData.status === 200){
        if(loginUserData.data){
          if(loginUserData.data.token)
          localStorage.setItem("token", loginUserData.data.token)
          window.location.href='/'
        }
      }
    }
  },[loginUserData])

  useEffect(()=>{
    dispatch(getLoggedUser())
  },[])

  useEffect(()=>{
    if(loggedUserData){
      if(loggedUserData.status === 200){
        if(loggedUserData.data){
          setRole(loggedUserData.data.data.role)
        }
      }
    }
  },[loggedUserData])

  return (
    role === "" ? (
    <div className='login-page'>
      <Helmet>
        <title>{`SHORTCUT | تسجيل الدخول`}</title>
      </Helmet>
        <div className='login-page-wraper'>
            <h2>تسجيل الدخول</h2>
            <form>
                <label htmlFor="email-login"><span>*</span>رقم الهاتف</label>
                <input type='phone' id='email-login' value={email} placeholder='أدخل رقم الهاتف'
                onChange={(e)=> setEmail(e.target.value)}/>
                <label htmlFor="password-login"><span>*</span>كلمة المرور</label>
                <input type='password' id="password-login" value={password} placeholder='أدخل كلمة المرور'
                onChange={(e)=> setPassword(e.target.value)}/>
                <button type='submit' className='submit' onClick={(e)=> handelSubmit(e)}>
                {
                btnLoaderStatus ? (
                  <BtnLoader/>
                ):(
                  "تسجيل الدخول"
                )
                }
                </button>
            </form>
            {/* <Link to="/forgot-password" className='forget-password'>نسيت كلمة المرور؟</Link> */}
        </div>
        <div className='signup'>
            <Link to="/signup">ان لم يكن لديك حساب قم بالتسجيل من هنا</Link>
        </div>
    </div>
    ):(navigate('/'))
  )
}

export default Login