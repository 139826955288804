import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../utilities/Sidebar";
import { Link } from "react-router-dom";
import { ChangeDeliverStatus, ChangePaymentStatus, getAllOrders } from "../../redux/actions/orderAction";
import Spiner from "../../utilities/Spiner";
import Check from '../../images/svg/check-mark.svg'
import OrderImg from '../../images/svg/car.svg'
import { useNavigate } from "react-router-dom";
import { getLoggedUser } from "../../redux/actions/authAction";
import Pagination from "../../utilities/Pagination";
import { Helmet } from "react-helmet-async";

const ManageOrders = () => {
  const [ordersList, setOrdersList] = useState([]);
  const [ordersLoading, setOrdersLoading] = useState(true)
  const [allOrders, setAllOrders] = useState([]);
  const [pageNumber, setPageNumber] = useState("");
  const [pageResult, setPageResult] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const ordersData = useSelector((state) => state.orderReducer.orders);
  const loggedUserData = useSelector(state=>state.authReducer.getMe)
  const dispatch = useDispatch();


  const navigate = useNavigate()
  const limit = 20

  useEffect(() => {
    dispatch(getLoggedUser())
    dispatch(getAllOrders(`limit=${limit}`));
  }, []);

  useEffect(() => {
    if (ordersData) {
      if (ordersData.status === 200) {
        if (ordersData.data) {
          setPageResult(ordersData.data.paginationResult)
          setOrdersList(ordersData.data.data);
          setAllOrders(ordersData.data.data)
          setOrdersLoading(false)
        }
      }
    }
  }, [ordersData]);

  //handel payment

  const paymentData = useSelector(state=> state.orderReducer.paymentStatus)

  useEffect(()=>{
    if(paymentData){
      if(paymentData.status === 200){
        window.location.reload()
      }
    }
  },[paymentData])

  const handelPayment = (id)=>{
    dispatch(ChangePaymentStatus(id))
  }


  //handel deliver
  const deliverData = useSelector(state=> state.orderReducer.deliverStatus)

  useEffect(()=>{
    if(deliverData){
      if(deliverData.status === 200){
        window.location.reload()
      }
    }
  },[deliverData])

  const handelDeliver = (id)=>{
    dispatch(ChangeDeliverStatus(id))
  }

  useEffect(()=>{
    if(loggedUserData){
      if(loggedUserData.status === 200){
        if(loggedUserData.data){
          if(loggedUserData.data.data.role !== "admin"){
            navigate('/')
          }
        }
      }else if(loggedUserData.status === 401){
        navigate('/')
      }
    }
  },[loggedUserData])

  // const onSearchUser = (searchValue)=>{
  //   if(searchValue !== ""){
  //     const filtredOrdersList = allOrders.filter((order)=> order.id.includes(searchValue))
  //     setOrdersList(filtredOrdersList)
  //   }else{
  //     setOrdersList(allOrders)
  //   }
  // }


  useEffect(()=>{
    dispatch(getAllOrders(`limit=${limit}&page=${pageNumber}`));
  }, [pageNumber])

  //to get page number
  const getPage = (pageNumber)=>{
    setPageNumber(pageNumber)
  }

  return (
    <div className="admin-page">
       <Helmet>
        <title>{`SHORTCUT | إدارة الطلبات`}</title>
      </Helmet>
      <div className="container">
        <div className="admin-page-wraper">
          <Sidebar />
          <section className="dashboard-container">
            <h3>إدارة الطلبات</h3>
            {/* <div className="search-form">
              <input type="text" value={searchValue} placeholder="أدخل رقم الهاتف للمٌستخدم" onChange={(e)=> setSearchValue(e.target.value)}/>
            </div> */}
            <div className="add-wraper">
              {ordersLoading ? (
                <Spiner />
              ) : ordersList.length > 0 ? (
                ordersList.map((order, index) => (
                  <div className="coupon-card" key={index}>
                    <Link to={`/order-details/${order._id}`}>
                      <span className="order-number">#{order.id}</span>
                      <ul className="coupon-details">
                        <li>
                          <p>{order.user ? order.user.name: null}</p>
                          :اسم العميل
                        </li>
                        <li>
                          <p>{order.shippingAddress ? order.shippingAddress.city : null}</p>
                          :المحافظة
                        </li>
                        <li>
                          <p>{order.shippingAddress ? order.shippingAddress.details : null}</p>
                          :العنوان
                        </li>
                        <li>
                          <p>{order.paymentMethodType ? order.paymentMethodType : null}</p>
                          :طريقة الدفع
                        </li>
                        <li>
                          <p>{order.trnxId ? order.trnxId : null}</p>
                          :رقم العملية
                        </li>
                        <li>
                          <p>{order.orderPaymobId ? order.orderPaymobId : null}</p>
                          :رقم الطلب
                        </li>
                        <li>
                          <p>{order.shippingAddress ? order.shippingAddress.phone:null}</p>
                          :رقم الهاتف
                        </li>
                        <li>
                          <p>{order.createdAt}</p>
                          :تاريخ الطلب
                        </li>
                      </ul>
                      <div className="coupon-img">
                        <img src={OrderImg} alt="order"/>
                      </div>
                    </Link>
                    <div className="order-status">
                          <div className="order-deliver">
                            {
                              order.isDelivered ? (
                                <img src={Check} alt="ckeck"/>
                              ):null
                            }
                            <select onChange={()=>{handelDeliver(order._id)}} >
                              {
                                order.isDelivered ? (
                                  <>
                                  <option value="Delivered">Delivered</option>
                                  <option value="Not Delivered">Not Delivered</option>
                                  </>
                                ):(
                                  <>
                                  <option value="Not Delivered">Not Delivered</option>
                                  <option value="Delivered">Delivered</option>
                                  </>
                                )
                              }                           
                            </select>
                          </div>
                          <div className="order-paid">
                            {
                              order.isPaid ? (
                                <img src={Check} alt="ckeck"/>
                              ):null
                            }                           
                            <select onChange={()=>handelPayment(order._id)}>
                            {
                                order.isPaid ? (
                                  <>
                                  <option value="Paid">Paid</option>
                                  <option value="Not Paid">Not Paid</option>
                                  </>
                                ):(
                                  <>
                                  <option value="Not Paid">Not Paid</option>
                                  <option value="Paid">Paid</option>
                                  </>
                                )
                              }          
                            </select>
                          </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="no-items">لم يتم إستقبال طلبات حتى الأن</p>
              )}
            </div>
            <div className="pagnation-container">
              <Pagination pageResult={pageResult} onPress={getPage}/>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ManageOrders;
