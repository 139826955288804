import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux'
import Upload from "../../images/svg/upload-img.svg";
import Sidebar from "../../utilities/Sidebar";
import { createBrand } from "../../redux/actions/brandAction";
import { useNavigate } from "react-router-dom";
import { getLoggedUser } from "../../redux/actions/authAction";
import { Helmet } from "react-helmet-async";

const AddBrand = () => {
  const [imgURL, setImgURL] = useState(Upload);
  const [img, setImg] = useState(null);
  const [brandName, setBrandName] = useState("");

  const dispatch = useDispatch()
  const loggedUserData = useSelector(state=>state.authReducer.getMe)

  const navigate = useNavigate()

  const onChangeImg = (e)=>{
    if(e.target.files && e.target.files[0]){
      setImgURL(URL.createObjectURL(e.target.files[0]))
      setImg(e.target.files[0])
    }
  }

  const handelSubmit = async(e)=>{
    e.preventDefault()
    const formData = new FormData()
    formData.append('name', brandName)
    await formData.append('image', img)
    await dispatch(createBrand(formData))
    setImgURL(Upload)
    setBrandName("")
  }


  useEffect(()=>{
    dispatch(getLoggedUser())
  },[])



  useEffect(()=>{
    if(loggedUserData){
      if(loggedUserData.status === 200){
        if(loggedUserData.data){
          if(loggedUserData.data.data.role !== "admin"){
            navigate('/')
          }
        }
      }else if(loggedUserData.status === 401){
        navigate('/')
      }
    }
  },[loggedUserData])



  return (
      <div className="admin-page">
        <Helmet>
          <title>{`SHORTCUT | إضافة ماركة`}</title>
        </Helmet>
      <div className="container">
        <div className="admin-page-wraper">
          <Sidebar/>
          <section className="dashboard-container">
            <h3>إضافة ماركة جديدة</h3>
            <div className="add-wraper">
              <div className="upload-wraper">
                <label htmlFor="upload-img">
                  <img src={imgURL} alt="upload-img" loading='lazy'/>
                </label>
                <input
                  type="file"
                  id="upload-img"
                  onChange={onChangeImg}
                />
              </div>
              <form>
                <input type="text" placeholder="اسم الماركة" value={brandName} onChange={(e)=> setBrandName(e.target.value)}/>
                <input className="submit" type="submit" value="إضافة ماركة" onClick={(e)=> handelSubmit(e)}/>
              </form>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AddBrand;