import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../utilities/Sidebar";
import Trash from "../../images/svg/trash-white.svg";
import CouponImg from "../../images/svg/offer.svg";
import { deleteCoupon, getAllCoupons } from "../../redux/actions/couponAction";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getLoggedUser } from "../../redux/actions/authAction";
import { Helmet } from "react-helmet-async";

const ManageCoupons = () => {
  const [couponsList, setCouponsList] = useState([]);

  const dispatch = useDispatch();
  const couponsData = useSelector((state) => state.couponReducer.coupons);
  const loggedUserData = useSelector((state) => state.authReducer.getMe);

  const navigate = useNavigate();

  //delete coupon
  const handelDeleteCoupon = (id) => {
    dispatch(deleteCoupon(id));
    window.location.reload();
  };

  useEffect(() => {
    dispatch(getLoggedUser());
    dispatch(getAllCoupons());
  }, []);

  useEffect(() => {
    if (couponsData) {
      if (couponsData.status === 200) {
        if (couponsData.data) {
          setCouponsList(couponsData.data.data);
          console.log(couponsList);
        }
      }
    }
  }, [couponsData]);

  useEffect(()=>{
    if(loggedUserData){
      if(loggedUserData.status === 200){
        if(loggedUserData.data){
          if(loggedUserData.data.data.role !== "admin"){
            navigate('/')
          }
        }
      }else if(loggedUserData.status === 401){
        navigate('/')
      }
    }
  },[loggedUserData])

  return (
    <div className="admin-page">
       <Helmet>
        <title>{`SHORTCUT | إدارة الكوبونات`}</title>
      </Helmet>
      <div className="container">
        <div className="admin-page-wraper">
          <Sidebar />
          <section className="dashboard-container">
            <h3>إدارة الكوبونات</h3>
            <div className="add-wraper">
              {couponsList.length > 0 ? (
                couponsList.map((coupon, index) => (
                  <div className="coupon-card" key={index}>
                    <img
                      className="trash"
                      src={Trash}
                      alt="delete-coupon"
                      onClick={() => handelDeleteCoupon(coupon._id)}
                    />
                    <ul className="coupon-details">
                      <li>
                        <p>{coupon.name}</p>
                        :اسم الكوبون
                      </li>
                      <li>
                        <p>{coupon.discount}%</p>
                        :نسبة الخصم
                      </li>
                      <li>
                        <p>{coupon.expire}</p>
                        :تاريخ الإنتهاء
                      </li>
                    </ul>
                    <div className="coupon-img">
                    <img src={CouponImg} alt="coupon"/>
                    </div>
                  </div>
                ))
              ) : (
                <p className="no-items">
                  لم يتم إنشاء كوبونات خصم حتى الأن
                  <Link to="/admin/add-coupon">إنشاء كوبون</Link>
                </p>
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ManageCoupons;
