import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Plus from "../../images/svg/plus-black.svg";
import Minus from "../../images/svg/minus.svg";
import Trash from "../../images/svg/trash.svg";
import { deleteCartItems, updateQty } from "../../redux/actions/cartAction";
import serverUrl from "../../api/serverUrl";

const ProductCart = ({ product }) => {
  const [productCart, setProductCart] = useState([]);
  const [qty, setQty] = useState(product.count);
  const [productInCartId, setProductInCartId] = useState(product.count);

  const dispatch = useDispatch();

  useEffect(() => {
    if (product) {
      setProductCart(product);
      setProductInCartId(product._id);
    }
  }, []);

  //incress qty
  const incressQty = async (productInCartId) => {
    setQty(qty + 1);
    await dispatch(
      updateQty(productInCartId, {
        count: qty + 1,
      })
    );
    window.location.reload();
  };

  //decress qty
  const decressQty = async (productInCartId) => {
    if (qty > 1) {
      setQty(qty - 1);
      await dispatch(
        updateQty(productInCartId, {
          count: qty - 1,
        })
      );
      window.location.reload();
    }
  };

  return (
    <div className="item">
      <img
        className="remove-item"
        src={Trash}
        alt="remove-cart-item"
        onClick={() => {
          dispatch(deleteCartItems(productInCartId));
          window.location.reload();
        }}
      />
      <div className="product-details">
        <p className="total">{productCart.price * productCart.count}.00 LE</p>
        <div className="qty">
          <img
            src={Plus}
            alt="incress"
            onClick={() => incressQty(productInCartId)}
            loading="lazy"
          />
          <input
            type="number"
            value={qty}
            onChange={(e) => {
              setQty(e.target.value);
            }}
          />
          <img
            src={Minus}
            alt="decress"
            onClick={() => decressQty(productInCartId)}
            loading="lazy"
          />
        </div>
        <p className="price">{productCart.price}.00 LE</p>
      </div>
      <div className="product-card">
        <div>
          <h4 className="product-name">
            {productCart.product && productCart.product.title}
          </h4>
          <ul>
            <li>{productCart.color}</li>
          </ul>
        </div>
        <img
          src={
            productCart.product &&
            `${serverUrl}/products/${productCart.product.imageCover}`
          }
          alt="product-card"
        />
      </div>
    </div>
  );
};

export default ProductCart;
