import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Upload from "../../images/svg/upload-img.svg";
import Sidebar from "../../utilities/Sidebar";
import {createCategory,} from "../../redux/actions/categoryAction";
import { useNavigate } from "react-router-dom";
import { getLoggedUser } from "../../redux/actions/authAction";
import BtnLoader from "../../utilities/BtnLoader";
import { Helmet } from "react-helmet-async";

const AddCategory = () => {
  const [imgURL, setImgURL] = useState(Upload);
  const [img, setImg] = useState(null);
  const [categoryName, setCategoryName] = useState("");
  const [btnLoaderStatus, setBtnLoaderStatus] = useState(false)


  const dispatch = useDispatch();

  const loggedUserData = useSelector((state) => state.authReducer.getMe);
  const addCategoryData = useSelector((state) => state.categoryReducer.addCategory);

  const navigate = useNavigate();

  const onChangeImg = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImgURL(URL.createObjectURL(e.target.files[0]));
      setImg(e.target.files[0]);
    }
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
    setBtnLoaderStatus(true)
    const formData = new FormData();
    formData.append("name", categoryName);
    await formData.append("image", img);
    await dispatch(createCategory(formData));
    setImgURL(Upload);
    setCategoryName("");
    window.location.reload();
  };

  useEffect(() => {
    dispatch(getLoggedUser());
  }, []);


  useEffect(()=>{
    if(addCategoryData){
      setBtnLoaderStatus(false)
    }
  },[addCategoryData])



  useEffect(() => {
    if (loggedUserData) {
      if (loggedUserData.status === 200) {
        if (loggedUserData.data) {
          if (loggedUserData.data.data.role !== "admin") {
            navigate("/");
          }
        }
      } else if (loggedUserData.status === 401) {
        navigate("/");
      }
    }
  }, [loggedUserData]);

  return (
    <div className="admin-page">
       <Helmet>
        <title>{`SHORTCUT | إضافة تصنيف`}</title>
      </Helmet>
      <div className="container">
        <div className="admin-page-wraper">
          <Sidebar />
          <section className="dashboard-container">
            <h3>إضافة تصنيف جديد</h3>
            <div className="add-wraper">
              <div className="upload-wraper">
                <label htmlFor="upload-img">
                  <img src={imgURL} alt="upload-img" loading="lazy" />
                </label>
                <input type="file" id="upload-img" onChange={onChangeImg} />
              </div>
              <form>
                <input
                  type="text"
                  placeholder="اسم التصنيف"
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                />
                <button
                  className="submit"
                  type="submit"
                  onClick={(e) => handelSubmit(e)}
                >
                  {
                    btnLoaderStatus ? (
                      <BtnLoader/>
                    ):(
                      "إضافة تصنيف"
                    )
                  }
                </button>
              </form>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;
